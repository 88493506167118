<template>
    <v-col
      cols="2"
      class="d-flex flex-column justify-end"
      v-if="this.$store.state.refresh && this.$store.state.id == this.captain.id"
    >
      <v-btn
        outlined
        @click="OpenEdit"
        class="align-self-end neon-btn"
      >
        編集
      </v-btn>
      <v-dialog
        v-model="dialog"
        activator="parent"
        :width="cardWidth"
        persistent
        scrollable
      >
        <v-card elevation="5" style="max-height: 550px; overflow-y: auto;" dark>
          <v-card-text
            class="scroll-area"
            id="scroll-target"
          >
            <!-- ここにダイアログに表示するマークアップを追加 -->
            <v-row style="height: 100%;" justify="center" no-gutters>
              <v-col>
                <div
                  class="title-text pt-5 pb-2 pl-3"
                >
                  Team Plofile
                </div>
                <v-divider
                  color="#5A49B5"
                  no-gutters
                  class="mb-5 ml-3 mr-3"
                  style="border-width: 1.5px;"
                ></v-divider>
                <!-- チーム画像 -->
                <v-card
                  :style="{ 'background-image': `url(${backgroundImage})`,
                            'background-size': 'contain',
                            'background-position': 'center',
                            'background-repeat': 'no-repeat',
                          }"
                  class="ma-1 card"
                >
                  <v-row style="height: 100%;" justify="center" align="center" no-gutters>
                    <v-col class="d-flex flex-row-reverse pr-3">
                      <v-btn
                        fab
                        small
                        style="opacity: 0.7"
                        color="black"
                        @click="$refs.fileInput.click()"
                      >
                        <v-icon color="white">mdi-camera-plus-outline</v-icon>
                        <input type="file" ref="fileInput" @change="onFileChange" style="display: none">
                      </v-btn>
                      <!-- 画像編集用ダイアログ -->
                      <v-dialog
                        v-model="dialog_2"
                        :width="cardWidth"
                      >
                      <v-card elevation="5" :height="CardMaxHeight" style="overflow: hidden;" dark>
                        <v-row style="height: 100%;" justify="center" no-gutters>
                          <v-col>
                            <!-- ヘッダーの行 -->
                            <v-row align="center" no-gutters>
                              <v-btn
                                fab
                                small
                                text
                                elevation="0"
                                class="ml-3"
                                @click="ClearSelectedImage()"
                              >
                                <v-icon size="26">mdi-arrow-left</v-icon>
                              </v-btn>
                                <div
                                  class="title-text pt-3 pb-2 pl-3"
                                >
                                  Edit Image
                                </div>
                                <v-col class="d-flex justify-end pr-3">
                                  <v-btn
                                    outlined
                                    class="neon-btn"
                                    @click="saveCroppedImage"
                                  >
                                    適用
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <!-- ヘッダーの行 -->
                              <v-divider color="#5A49B5" no-gutters class="mb-2"></v-divider>
                              <!-- 画像切り取り -->
                              <v-row justify="center" no-gutters>
                                <v-col class="mr-5 ml-5">
                                  <vue-cropper
                                    v-if="selectedImage"
                                    ref="cropper"
                                    :src="selectedImage"
                                    :aspect-ratio="5/2"
                                    :viewMode="1"
                                    :autoCrop="true"
                                    :autoCropArea="1"
                                    :center="false"
                                    :background="false"
                                    dragMode="move"
                                    :modal="false"
                                    :guides="true"
                                    :highlight="true"
                                    :cropBoxResizable="false"
                                    :cropBoxMovable="true"
                                    :movable="true"
                                    :scalable="false"
                                    :minContainerWidth="300"
                                    :minContainerHeight="300"
                                    @ready="onCropperReady"
                                    :toggleDragModeOnDblclick="false"
                                  ></vue-cropper>
                                </v-col>
                              </v-row>
                            <!-- 画像切り取り -->
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-dialog>
                      <!-- 画像編集用ダイアログ -->
                    </v-col>
                    <v-col>
                      <v-btn
                        fab
                        small
                        style="opacity: 0.7"
                        color="black"
                        @click="deleteCroppedImage"
                      >
                        <v-icon color="white">mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
                <!-- チーム画像 -->
                <!-- チーム紹介文編集 -->
                <v-row justify="center" no-gutters>
                  <v-col cols="11">
                    <div class="text-h6 text-xl-body-1 font-weight-bold pb-2 pt-5">
                      チーム紹介
                    </div>
                  </v-col>
                  <v-col cols="11">
                    <v-textarea
                      v-model="introductions"
                      color="#7A7585"
                      clearable
                      outlined
                      auto-grow
                      counter
                      placeholder="テキストを入力"
                      rows="5"
                      maxlength="200"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <!-- チーム紹介文編集 -->
                <!-- 連続マッチング設定
                <v-row justify="center" no-gutters>
                  <v-col cols="10">
                    <div class="text-h6 text-xl-body-1 font-weight-bold">
                      連続マッチング設定
                    </div>
                    <div class="text-subtitle-2" style="color: rgb(160, 160, 160);">
                      連続マッチング設定を行うと、レート対戦において、その時間内にマッチングしたチームとはマッチングしなくなります。
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-select
                      :items="times"
                      item-text="text"
                      item-value="value"
                      v-model="selectedTime"
                      :menu-props="{ offsetY: true }"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                連続マッチング設定 -->
                <!-- レート表示設定
                <v-row justify="center" no-gutters class="mt-2">
                  <v-col cols="10">
                    <div class="text-h6 text-xl-body-1 font-weight-bold">
                      レート表示設定
                    </div>
                    <div class="text-subtitle-2" style="color: rgb(160, 160, 160);">
                      「レートを表示しない」設定は、レート対戦は楽しみたいけどレートの変動は見たくないという方向けです。<br> (初期状態ではレートを表示するように設定されています。)
                    </div>
                  </v-col>
                  <v-col cols="10">
                    <v-checkbox
                      v-model="no_rating"
                      label="レートを表示しない"
                      v-if="this.teams.no_rating === false"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="no_rating_1"
                      label="レートを表示する"
                      v-if="this.teams.no_rating === true"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                レート表示設定 -->
                <!-- チームメンバー募集中選択 -->

                <v-row justify="center" no-gutters class="mt-2">
                  <v-col cols="11">
                    <div class="text-h6 text-xl-body-1 font-weight-bold">
                      チームメンバー募集状況設定
                    </div>
                    <div class="text-subtitle-2" style="color: rgb(160, 160, 160);">
                      募集中を選択していない場合、他のユーザーをチームに招待することはできません。<br> (初期状態では募集中に設定されています。)
                    </div>
                  </v-col>
                  <v-col cols="11">
                    <v-checkbox
                      v-model="recruiting"
                      label="募集中"
                      v-if="this.teams.recruiting_members === false"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="recruiting_1"
                      label="募集停止中"
                      v-if="this.teams.recruiting_members === true"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <!-- チームメンバー募集中選択 -->
                <!-- キャンセル・保存ボタン -->
                <v-row no-gutters justify="center" class="pt-4 pb-8">
                  <v-col cols="11">
                    <v-row no-gutters justify="center">
                      <v-col cols="5" sm="4" md="3">
                        <v-btn
                          class="neon-btn"
                          tile
                          outlined
                          width="100%"
                          :disabled="isSendDisabled"
                          @click="sendTeamProfile"
                        >
                          保存
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="5" sm="4" md="3">
                        <v-btn
                          class="neon-cancel-btn"
                          tile
                          outlined
                          width="100%"
                          @click="ClearProfile()"
                        >
                          キャンセル
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- キャンセル・保存ボタン -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
</template>

<script>
import VueCropper from "vue-cropperjs";
import 'cropperjs/dist/cropper.css';
import store from '../store';

export default {
  components: {
    VueCropper
  },
  props: {
    teams: [],
    team_image: [],
    captain: [],
  },
  data(){
    return {
      dialog: false,
      dialog_1: false,
      dialog_2: false,
      no_rating: false,
      no_rating_1: false,
      introductions: "",
      noImage: false,
      // 切り抜く前の画像
      selectedImage: null,
      backgroundImage: null,
      // 切り抜いた後の画像
      croppedImage: null,
      selectedTime: "",
      times: [
        {
          text: "1時間以内にマッチングしたチームとはマッチングしない",
          value: 1,
        },
        {
          text: "3時間以内にマッチングしたチームとはマッチングしない",
          value: 3,
        },
        {
          text: "5時間以内にマッチングしたチームとはマッチングしない",
          value: 5,
        },
        {
          text: "なし",
          value: 7,
        },
      ],
      recruiting: false,
      recruiting_1: false,
      zoomValue: 0.1,
      minZoom: 0.1,
      maxZoom: 2,
    }
  },

  watch: {
    introductions(newVal) {
      const newLines = (newVal.match(/\n/g) || []).length;
      const effectiveLength = newVal.length + newLines;  // 1文字の改行が2文字としてカウントされるため調整

      if (effectiveLength > 200) {
        this.introductions = newVal.substring(0, 200 - newLines);
      }
    }
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '60%'
        case 'md': return '50%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },
    imageCardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '60%'
        case 'lg': return '50%'
        case 'xl': return '40%'
      }
      return 0
    },
    CardMaxHeight () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '400px'
        case 'sm': return '500px'
        case 'md': return '550px'
        case 'lg': return '550px'
        case 'xl': return '550px'
      }
      return 0
    },
    isSendDisabled() {
      return !(
        // 下記のいずれかが満たされていれば保存ボタンが押せる
        this.croppedImage
        || this.noImage
        || this.introductions
        // || this.selectedTime
        // || (this.no_rating === true || this.no_rating_1 === true)
        || (this.recruiting === true || this.recruiting_1 === true)
        );
    },

  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setCardHeight);
  },

  methods: {

    // プロフィール編集ダイアログを開く際の処理
    OpenEdit() {
      this.backgroundImage = this.team_image
      this.introductions = this.teams.introduction
      this.dialog = true
      this.$nextTick(() => {
        let i = document.getElementById("scroll-target");
        if (i) {
          i.scrollTop = 0;
        }
      });
    },

    // プロフィール編集ダイアログを閉じる際の処理
    ClearProfile: function() {
      this.introductions = "";
      this.selectedTime = "";
      this.selectedImage = "";
      this.no_rating = "";
      this.recruiting = "";
      this.backgroundImage = this.team_image;
      this.noImage = false
      this.dialog = false;
    },

    // チーム画像を選択した際の処理（トリミング用ダイアログが開かれる）
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.selectedImage = null;
        this.$nextTick(() => {
          this.selectedImage = e.target.result;
        });
      };
      reader.readAsDataURL(files[0])
      this.dialog_2 = true
    },

    // チーム画像のトリミングをキャンセルする際の処理（選択された画像は初期化される）
    ClearSelectedImage: function(){
      this.selectedImage = null
      this.$refs.fileInput.value = '';
      this.dialog_2 = false
    },

    // 切り抜き枠の初期位置を設定するメソッド
    onCropperReady() {
      this.$nextTick(() => {
        // 画像の中心座標を計算
        const image = this.$refs.cropper.getImageData()
        const centerX = image.naturalWidth / 2
        // 切り抜き枠の中心座標を計算
        let cropBoxX = centerX
        let cropBoxY = 0
        // 切り抜き枠の位置とサイズを設定
        this.$refs.cropper.setCropBoxData({
          left: cropBoxX,
          top: cropBoxY,
        })
      })
    },

    // 画像を切り抜くメソッド(下のsaveCroppedImageで使用)
    async getCroppedImageData(targetWidth, targetHeight) {
      if (!this.$refs.cropper) return;
      const canvas = this.$refs.cropper.getCroppedCanvas({
        width: targetWidth,
        height: targetHeight
      })

      return new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob)
        }, 'image/jpeg')
      })
    },

    async saveCroppedImage() {
      // getCroppedImageDataメソッドによって切り抜かれた画像をリサイズして取得
      const resizedImageData = await this.getCroppedImageData(1920, 768);
      this.croppedImage = resizedImageData;

      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundImage = e.target.result;
      };
      reader.readAsDataURL(resizedImageData);

      this.dialog_2 = false;
    },

    // ×ボタンを押した際に切り抜いた後の画像を初期化する
    deleteCroppedImage() {
      if (this.selectedImage) {
        this.croppedImage = null
        this.selectedImage = null
        this.$refs.fileInput.value = '';
        this.backgroundImage = this.team_image;
        this.noImage = false
      } else {
        this.croppedImage = null
        this.backgroundImage = null
        if (this.team_image) {
          this.noImage = true
        }
      }
    },

    // 送信メソッド
    async sendTeamProfile() {
      const formData = new FormData();

      if (this.croppedImage) {
        formData.append("image", this.croppedImage, "team-image.jpg");
      }
      if (this.introductions) {
        formData.append("introduction", this.introductions);
      }
      /* 現時点ではまだ不要
      if (this.selectedTime) {
        if (this.selectedTime === 1 || this.selectedTime === 3 || this.selectedTime === 5)
          formData.append("no_matching", this.selectedTime);
        else if (this.selectedTime === 7)
          formData.append("no_matching", null);
      }
      if (this.no_rating === true) {
        formData.append("no_rating", true);
      }
      if (this.no_rating_1 === true) {
        formData.append("no_rating", false);
      }
      */
      if (this.recruiting === true) {
        formData.append("recruiting_members", true);
      }
      if (this.recruiting_1 === true) {
        formData.append("recruiting_members", false);
      }

      // noImageがtrueの場合、チームの画像を削除するリクエストを送信
      if (this.noImage === true) {
        await this.$sendFormRequest("post", "/delete_team_image/")
          .then((response) => {
            store.commit('setTeam', response.data)
          })
      }
      // Send a single axios request with the requestData object
      if (formData) {
        await this.$sendFormRequest("put", "/teams/", formData)
          .then((response) => {
            store.commit('setTeam', response.data)
          })
      }
      this.dialog = false;
      location.reload();
    },
  },
}
</script>

<style scoped>
.card {
  background-color: #1e1e1e;
  position: relative;
  width: 99%;
  padding-top: 14%;
  padding-bottom: 14%; /* 高さ2対横幅5のアスペクト比のために 2 / 5 * 100% = 40% */
  overflow: hidden;
  border: none !important;
  box-shadow: none !important;
}
.scroll-area {
  height: auto;
  overflow: hidden;
}
</style>