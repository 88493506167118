<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          About
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          C.C.Communityとは
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            C.C.Communityとは、FINAL FANTASY XIV(以下FF14)におけるCrystalline Conflict(以下クリコン)の対戦・交流を目的としたWebサービスです。<br>
          </v-card-text>
          <v-card-text class="pt-0 pl-3 pb-2 pr-1 custom-line-height">
            当サービスに登録することで、チーム作成、フリー対戦の機能をご利用いただけます。<br>
            現在公式・非公式で様々な大会が開かれておりチーム戦が盛んなクリコンですが、チーム練習をするには日時や場所を決めてDiscordやTwitterで募集するというのが一般的だと思います。<br>
            そこで当サービスでは、前もって予定を決める必要もなく、より気楽に、好きな時間にチーム戦ができる仕組みを作りました。<br>
          </v-card-text>
          <v-card-text class="pt-0 pl-3 pb-2 pr-1 custom-line-height">
            フリー対戦では、FF14ゲーム内のカジュアルマッチの様な感覚でチーム戦を楽しむことができます。<br>
            まだまだ機能は少ない当サービスではありますが、今後の需要や様子を見て、チームのレート対戦や大会・スクリムを募集できる掲示板機能、当サービスに登録していただいているユーザー様のジョブの使用率統計機能等を追加していきたいと考えています。<br>
            <br><router-link to="/terms" class="no-underline">利用規約はこちらになります。</router-link>当サービスの一切のサービスを利用した時点で当規約に同意されたものとみなされますので、必ず最後までお読みください。<br>
            <span style="color: rgb(255, 30, 30)">当サービスは個人のFF14ファンが運営する非公式のWebサービスです。株式会社スクウェア・エニックス・ホールディングス様、他関連企業様とは一切関係ありません。</span>
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          ユーザー登録について
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            当サービスの各機能をご利用いただくにはユーザー登録及びログインが必要です。
            ユーザー登録は<span @click="TwitterLogin" class="clickable-text">こちら</span>からか、画面右上のユーザーアイコンから可能です。<br>
            ユーザー登録にはTwitter認証が必要です。連携によって当サイトに許可される権限は閲覧のみでございますので、勝手に連携したアカウントを利用してツイートやリツイート等を行うことは一切ございません。
            認証後入力フォームが表示されますので、<a href="https://jp.finalfantasyxiv.com/lodestone/" class="no-underline">The Lodestone</a>のマイキャラクターページのURLを貼り付けてください。
            ゲーム内キャラクターと当サービスのユーザーを結びつけることでユーザー登録完了となります。<br>
            登録後はTwitter認証を行うだけでログインすることが可能になります。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          チーム作成について
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            当サービスに登録しログインされたユーザー様は、誰でも簡単にチームを作成することが出来ます。チーム作成時に必要なのはチーム名の登録だけです。(<router-link to="/team_create" class="no-underline">チーム作成はこちらから</router-link>)
            <br>チームを作成したユーザー様がそのチームのリーダーとなり、チームプロフィールの編集やチームメンバーの招待、フリー対戦への参加申請を行うことが出来ます。
          </v-card-text>
          <v-card-text class="pt-0 pl-3 pb-2 pr-1 custom-line-height">
            チームメンバーはリーダーを含めて最大7人までです。
            チームへの参加は1ユーザーにつき1チームまでとさせていただきます。既にチームに参加されていて他のチームに参加したい場合は、現在のチームを脱退する必要がありますので、ご了承ください。
            <br>チームの解散・脱退・メンバーの除名・リーダー権限の委譲に関しましては、各チームページより可能です。チームの解散、メンバーの除名及びリーダー権限の委譲は、チームリーダーのみ利用可能な機能となっています。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          フリー対戦について
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            フリー対戦では、クリコンのチーム戦の対戦相手を探すことが出来ます。
            <br>参加条件は当サービスでチームを作成し、そのチームメンバーの人数がリーダーを含めて5人以上であることのみです。
          </v-card-text>
          <v-card-text class="pt-0 pl-3 pb-2 pr-1 custom-line-height">
            マッチング後は、カスタムマッチを利用してマッチングしたチーム同士でチーム戦を行います。
            <br>募集側と参加側をランダムで振り分けさせていただきますので、募集側になったチームのリーダー様はゲーム内でカスタムマッチのパーティ募集をお願いします。その際に、<span style="color: rgb(255, 30, 30)">自由コメント欄に「CCC フリー対戦」と表記し、パスワードを「1122」と設定</span>して募集していただきますようよろしくお願いします。
            参加側のチームは極力パーティを組んだ状態で募集に入るようにお願いします。
            <br>また、当サービスを利用した対戦は全て<span style="color: rgb(255, 30, 30)">〈Meteor DC〉</span>(以下メテオ)で行うようにお願いします。
          </v-card-text>
          <v-card-text class="pt-0 pl-3 pb-2 pr-1 custom-line-height">
            メテオで行う理由は、当サービス運営者のメインキャラクターがメテオにあることと、メテオはDCテレポが安定して行えること、メテオをPvPで盛り上げたいという3つです。
            <br>フリー対戦について、詳しくは<router-link to="/rule" class="no-underline">ルールページ</router-link>にまとめています。初めてフリー対戦に参加する前に必ずご覧ください。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>

export default {
  data() {
    return {

    };
  },

  mounted() {
    window.scrollTo(0, 0);
    document.title = "C.C.Community - サイトについて";
  },

  methods: {
    TwitterLogin: function(){
      if (this.$store.state.refresh) {
        this.$router.push('/user/' + this.$store.state.id)
      } else {
        window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"
      }
    },
  }
}

</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
; /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
.custom-line-height {
  line-height: 1.8;  /* 行間を1.8に設定 */
}
.no-underline {
  text-decoration: none;
}
.clickable-text {
  color: 	#0066c0;
  cursor: pointer;
}

</style>