<template>
  <div>
    <v-list
      class="pa-0"
      v-for="team in team_admission" :key="team.id"
    >
      <v-divider></v-divider>
      <v-menu
        close-on-content-click
        offset-y
        dark
      >
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-title>{{ team.name }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item :to="'/team/' + team.id">
            <v-list-item-title>チームページへ</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openDialog(team.id)">
            <v-list-item-title>招待を受ける</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openDeclineDialog(team.id)">
            <v-list-item-title>招待を辞退する</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


      <!-- 招待を受けるを選択すると開かれるダイアログ -->
      <v-dialog
        v-model="dialog[team.id]"
        :width="cardWidth_1"
      >
        <v-card
          elevation="5"
          dark
        >
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col>
              <div
                class="title-text pt-3 pb-2 pl-3"
                align="center"
              >
                From <br> {{ team.name }}
              </div>
              <v-divider
                color="#5A49B5"
                no-gutters
                class="mb-5 ml-3 mr-3"
                style="border-width: 1.5px;"
              ></v-divider>
            </v-col>
          </v-row>
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col cols="10">
              <h2 class="text-body-1 mb-6" align="center">{{ team.name }}に正式加入しますか？</h2>
              <p class="mb-4 text-medium-emphasis text-body-2" align="center">
                ※同時に複数のチームに参加することはできません。<br>
                したがって、チームに加入するとチームから離脱しない限り他のチームに参加することはできません。<br><br>
                また、チームに加入すると同時に現在届いている他のチームの招待を全て辞退します。
              </p>
            </v-col>
          </v-row>
          <!-- キャンセルand更新ボタン -->
          <v-row no-gutters justify="center" class="mt-2 pb-5">
            <v-spacer></v-spacer>
            <v-col cols="3" sm="2" md="2">
              <v-btn
                class="neon-btn"
                tile
                outlined
                width="100%"
                @click="acceptRecruiting(team.id)"
              >
                正式加入
              </v-btn>
              <!-- 招待が成功したときに表示されるダイアログ -->
              <v-dialog
                v-model="dialog_1"
                :width="cardWidth"
                persistent
              >
                <v-card
                  dark
                  elevation="5"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="success"
                    icon="mdi-check-circle"
                    size="112"
                  >mdi-check-circle</v-icon>
                  <h2 class="title-text mb-6">Success!</h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    {{ team.name }}に正式加入しました！<br>
                    チーム戦を楽しみましょう！
                  </p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="text-end">
                    <v-btn
                      class="neon-btn"
                      width="90"
                      @click="successAccepting"
                    >
                      閉じる
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <!-- 招待が成功したときに表示されるダイアログ -->
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" sm="2" md="2">
              <v-btn
                class="neon-cancel-btn"
                tile
                outlined
                width="100%"
                @click="closeDialog(team.id)"
              >
                キャンセル
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <!-- キャンセルor更新ボタン -->
        </v-card>
      </v-dialog>
      <!-- 招待を受けるを選択すると開かれるダイアログ -->


      <!-- 招待を断るを選択すると開かれるダイアログ -->
      <v-dialog
        v-model="dialog_decline[team.id]"
        :width="cardWidth_1"
      >
        <v-card
          elevation="5"
          dark
        >
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col>
              <div
                class="title-text pt-3 pb-2 pl-3"
                align="center"
              >
                From <br> {{ team.name }}
              </div>
              <v-divider
                color="#5A49B5"
                no-gutters
                class="mb-5 ml-3 mr-3"
                style="border-width: 1.5px;"
              ></v-divider>
            </v-col>
          </v-row>
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col cols="10">
              <h2 class="text-body-1 mb-6" align="center">{{ team.name }}からの招待を辞退しますか？</h2>
              <p class="mb-4 text-medium-emphasis text-body-2" align="center">
                ※招待を辞退すると、再び招待されない限りそのチームに参加することはできなくなります。
                <br>
                <br>
                一度辞退すると辞退の取り消しはできません。
              </p>
            </v-col>
          </v-row>
          <!-- キャンセルand更新ボタン -->
          <v-row no-gutters justify="center" class="mt-2 pb-5">
            <v-spacer></v-spacer>
            <v-col cols="3" sm="2" md="2">
              <v-btn
                class="neon-btn"
                tile
                outlined
                width="100%"
                @click="declineRecruiting(team.id)"
              >
                辞退する
              </v-btn>
              <!-- 辞退が成功したときに表示されるダイアログ -->
              <v-dialog
                v-model="dialog_2"
                :width="cardWidth"
                persistent
              >
                <v-card
                  dark
                  elevation="5"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="success"
                    icon="mdi-check-circle"
                    size="112"
                  >mdi-check-circle</v-icon>
                  <h2 class="title-text mb-6">Success!</h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    {{ team.name }}からの招待を辞退しました。
                  </p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="text-end">
                    <v-btn
                      class="neon-btn"
                      width="90"
                      @click="successDeclining"
                    >
                      閉じる
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <!-- 辞退が成功したときに表示されるダイアログ -->
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="3" sm="2" md="2">
              <v-btn
                class="neon-cancel-btn"
                tile
                outlined
                width="100%"
                @click="closeDeclineDialog(team.id)"
              >
                キャンセル
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <!-- キャンセルor更新ボタン -->
        </v-card>
      </v-dialog>
      <!-- 招待を断るを選択すると開かれるダイアログ -->
    </v-list>
  </div>
</template>

<script>
import store from '../store';

export default {
  props: {
    users: [],
    team_admission: [],
  },
  data(){
    return{
      dialog: {},
      dialog_decline: {},
      dialog_1: false,
      dialog_2: false,
    }
  },
  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '55%'
        case 'md': return '45%'
        case 'lg': return '35%'
        case 'xl': return '28%'
      }
      return 0
    },
    cardWidth_1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '50%'
        case 'lg': return '45%'
        case 'xl': return '35%'
      }
      return 0
    },
  },
  methods: {

    openDialog(teamId) {
      this.$set(this.dialog, teamId, true);
    },

    openDeclineDialog(teamId) {
      this.$set(this.dialog_decline, teamId, true);
    },

    // 招待を受ける際の処理
    acceptRecruiting(team_id) {
      const url_join_team = "/join_or_leave_team/" + team_id;
      const url_delete_recruiting = "/delete_recruiting/";

      this.$sendRequest('put', url_join_team, { admission_status: "official" })
        .then((response) => {
          store.commit('setTeam', response.data.team)
          this.$sendRequest('delete', url_delete_recruiting)
            .then(() => {
            this.dialog[team_id] = false;
            this.dialog_1 = true;
          })
        })
    },

    // 正式加入が成功したあと開かれたダイアログを閉じる処理（閉じるとページが更新される）
    successAccepting(){
      this.dialog_1 = false
      location.reload()
    },

    // 正式加入を選択するダイアログを閉じる（キャンセルボタン）
    closeDialog(teamId) {
      this.dialog[teamId] = false;
    },

    // 招待を辞退する処理
    declineRecruiting(teamId) {
      const team_id = teamId
      const url_decline_recruiting = "/join_or_leave_team/" + team_id;
      this.$sendRequest('delete', url_decline_recruiting)
        .then(() => {
          this.dialog_2 = true
        })
    },

    // 辞退が成功したあと開かれたダイアログを閉じる処理（閉じるとページが更新される）
    successDeclining(){
      this.dialog_2 = false
      location.reload()
    },

    // 辞退を選択するダイアログを閉じる（キャンセルボタン）
    closeDeclineDialog(teamId) {
      this.dialog_decline[teamId] = false;
    },

  },
}
</script>