import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UserRegister from "../views/UserRegister.vue";
import CheckRegister from "../views/CheckRegister.vue";
import UserReference from "../views/UserReference.vue";
import UserPage from "../views/UserPage.vue";
import TeamPage from "../views/TeamPage.vue";
import TeamCreate from "../views/TeamCreate.vue";
import FreeMatch from "../views/FreeMatch.vue";
import Waiting from "../views/Waiting.vue";
import InMatch from "../views/InMatch.vue";
import AboutApp from "../views/AboutApp.vue";
import Terms from "../views/Terms.vue";
import Faq from "../views/Faq.vue";
import Rule from "../views/Rule.vue";
import Search from "../views/Search.vue";
import Contact from "../views/Contact.vue";
import Jobs from "../views/Jobs.vue";
import TeamSearch from "../views/TeamSearch.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/user_register/:id?",
    name: "UserRegister",
    component: UserRegister,
  },
  {
    path: "/check_register/",
    name: "CheckRegister",
    component: CheckRegister,
  },
  {
    path: "/user_reference/:id?",
    name: "UserReference",
    component: UserReference,
  },
  {
    path: "/user/:id",
    name: "UserPage",
    component: UserPage,
  },
  {
    path: "/team/:id",
    name: "TeamPage",
    component: TeamPage,
  },
  {
    path: "/team_create",
    name: "TeamCreate",
    component: TeamCreate,
  },
  {
    path: "/free_match",
    name: "FreeMatch",
    component: FreeMatch,
  },
  {
    path: "/waiting",
    name: "Waiting",
    component: Waiting,
  },
  {
    path: "/in_match",
    name: "InMatch",
    component: InMatch,
  },
  {
    path: "/about_app",
    name: "AboutApp",
    component: AboutApp,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/rule",
    name: "Rule",
    component: Rule,
  },
  {
    path: "/search/:keyword",
    name: "Search",
    component: Search,
    props: true
  },
  {
    path: "/jobs",
    name: "Jobs",
    component: Jobs,
  },
  {
    path: "/team_search",
    name: "TeamSearch",
    component: TeamSearch,
  },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
