<template>
  <v-card
    class="mx-auto"
    outlined
    dark
  >
    <v-list>
      <v-menu
        close-on-content-click
        offset-y
        dark
      >
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-avatar
              tile
              size="40"
            >
              <img :src="captain.face" alt="avatar">
            </v-list-item-avatar>
            <v-list-item-title
              class="text-h5 font-weight-regular"
            >
              {{ captain.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item :to="'/user/' + captain.id">
            <v-list-item-title>ユーザーページへ</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.state.refresh && $store.state.id == captain.id" @click="dialog_warning_cap=true">
            <v-list-item-title>チーム解散</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-dialog
        v-model="dialog_warning_cap"
        :width="cardWidth"
      >
        <v-card
          dark
          elevation="5"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="warning"
            icon="mdi-alert-circle"
            size="112"
          >mdi-alert-circle</v-icon>
          <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
          <p class="mb-4 text-medium-emphasis text-body-2">
            この操作を実行すると取り消すことはできません。<br>
            本当にチームを解散しますか？
          </p>
          <v-divider class="mb-4"></v-divider>
          <v-row justify="center">
            <v-col>
              <v-btn
                class="neon-btn"
                @click="disbandTeam"
              >
                解散する
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="neon-cancel-btn"
                @click="dialog_warning_cap=false"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-list>
    <v-divider></v-divider>

    <v-list
      v-for="member in members" :key="member.name"
      class="pa-0"
    >
      <v-menu
          close-on-content-click
          offset-y
          dark
        >
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-avatar
              tile
              size="40"
            >
              <img :src="member.face" alt="avatar">
            </v-list-item-avatar>
            <v-list-item-title
              class="text-h6 font-weight-regular pt-2 pb-2"
            >
              {{ member.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item :to="'/user/' + member.id">
            <v-list-item-title>ユーザーページへ</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.state.refresh && $store.state.id == member.id" @click="dialog_warning_mem=true">
            <v-list-item-title>チーム脱退</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.state.refresh && $store.state.id == captain.id" @click="openDialog_1(member.id)">
            <v-list-item-title>リーダー権限の委譲</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="$store.state.refresh && $store.state.id == captain.id" @click="openDialog_2(member.id)">
            <v-list-item-title>除名する</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider></v-divider>

      <v-dialog
        v-model="dialog_warning_cap_1[member.id]"
        :width="cardWidth"
      >
        <v-card
          dark
          elevation="5"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="warning"
            icon="mdi-alert-circle"
            size="112"
          >mdi-alert-circle</v-icon>
          <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
          <p class="mb-4 text-medium-emphasis text-body-2">
            この操作を実行すると取り消すことはできません。<br>
            {{ member.name }}さんにリーダー権限を委譲しますか？
          </p>
          <v-divider class="mb-4"></v-divider>
          <v-row justify="center">
            <v-col>
              <v-btn
                class="neon-btn"
                @click="changeLeader(member.id)"
              >
                委譲する
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="neon-cancel-btn"
                @click="closeDialog_1(member.id)"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialog_warning_cap_2[member.id]"
        :width="cardWidth"
      >
        <v-card
          dark
          elevation="5"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="warning"
            icon="mdi-alert-circle"
            size="112"
          >mdi-alert-circle</v-icon>
          <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
          <p class="mb-4 text-medium-emphasis text-body-2">
            この操作を実行すると取り消すことはできません。<br>
            {{ member.name }}さんを除名しますか？
          </p>
          <v-divider class="mb-4"></v-divider>
          <v-row justify="center">
            <v-col>
              <v-btn
                class="neon-btn"
                @click="deleteMember(member.id)"
              >
                除名する
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                class="neon-cancel-btn"
                @click="closeDialog_2(member.id)"
              >
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-list>

    <v-dialog
      v-model="dialog_warning_mem"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          icon="mdi-alert-circle"
          size="112"
        >mdi-alert-circle</v-icon>
        <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          この操作を実行すると取り消すことはできません。<br>
          本当にチームを脱退しますか？
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="neon-btn"
              @click="leaveTeam"
            >
              脱退する
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="dialog_warning_mem=false"
            >
              キャンセル
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>

export default {
  props: {
    captain: [],
    members: [],
    teams: [],
  },

  data() {
    return {
      dialog_warning_cap: false,
      dialog_warning_cap_1: {},
      dialog_warning_cap_2: {},
      dialog_warning_mem: false,
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '45%'
        case 'lg': return '35%'
        case 'xl': return '28%'
      }
      return 0
    },
  },

  methods: {

    // チーム解散
    disbandTeam() {
      const url = "/teams/";

      this.$sendRequest('delete', url)
        .then(() => {
          this.$store.commit('removeTeam');
          this.$store.commit('removeStatus');
          this.$router.push('/')
          alert('チームが正常に解散されました。');
        })
    },

    openDialog_1(memberId) {
      this.$set(this.dialog_warning_cap_1, memberId, true);
    },

    closeDialog_1(memberId) {
      this.dialog_warning_cap_1[memberId] = false;
    },

    // リーダー権限を委譲
    changeLeader(memberId) {
      const url = "/delete_member/" + memberId;

      this.$sendRequest('put', url)
        .then(() => {
          this.$store.commit('removeStatus');
          alert('リーダー権限の委譲に成功しました。');
          location.reload();
        })
    },

    openDialog_2(memberId) {
      this.$set(this.dialog_warning_cap_2, memberId, true);
    },

    closeDialog_2(memberId) {
      this.dialog_warning_cap_2[memberId] = false;
    },

    // メンバー除名
    deleteMember(memberId) {
      const url = "/delete_member/" + memberId;

      this.$sendRequest('delete', url)
        .then(() => {
          alert('メンバーが正常に除名されました。');
          location.reload();
        })
    },

    // チーム脱退
    leaveTeam() {
      const url = "/join_or_leave_team/" + this.$store.state.team.id;

      this.$sendRequest('delete', url)
        .then(() => {
          this.$store.commit('removeTeam');
          alert('チーム脱退が正常に行われました。');
          this.$router.push('/')
        })
    }
  },
};
</script>

<style>
.captain {
  font-family: 'Orbitron', sans-serif !important;
  color: #78ffac;
  text-shadow:
    0 0 1px #00ff62,
    0 0 3px #00ff62,
    0 0 5px #00ff62,
    0 0 7px #00ff62,
    0 0 9px #00ff62;
}
.member {
  font-family: 'Orbitron', sans-serif !important;
  color: #75e3ff;
  text-shadow:
    0 0 1px #06aad3,
    0 0 3px #06aad3,
    0 0 5px #06aad3,
    0 0 7px #06aad3,
    0 0 9px #06aad3;
}
</style>