<template>
  <v-main>

    <!-- 待機中のダイアログ -->
    <v-dialog
      v-model="dialog"
      :width="cardWidth"
      persistent
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <h2 class="title-text mb-4 mt-2" style="color: #f8f52f;">Waiting</h2>
        <v-progress-linear
          color=#35b3fc
          indeterminate
        ></v-progress-linear>
        <p class="mb-4 mt-4 text-medium-emphasis text-body-2">
          対戦相手の入室待ちです。<br>
          このブラウザタブは開いたままでお待ちください。<br>
          1分30秒経過しても相手チームの入室が確認できない場合、相手チームが辞退したこととなり、マッチングは不成立となります。
        </p>
      </v-card>
    </v-dialog>
    <!-- 待機中のダイアログ -->

    <!-- マッチング成功時のダイアログ -->
    <v-dialog
      v-model="dialog_success"
      :width="dialogWidth"
      persistent
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <h2 class="title-text mb-4 mt-2" style="color: #f8f52f;">Success!</h2>
        <v-divider class="mb-4"></v-divider>
        <p class="mb-4 mt-4 text-medium-emphasis text-body-2">
          マッチングが成立しました！<br>
          募集側のチームのリーダーがカスタムマッチのパーティ募集を立ててください。<br>
          (場所はMeteorデータセンター、自由コメント欄に「CCC フリー対戦」と入力し、パスワードを「1122」と設定してください。)
        </p>

        <v-row
          justify="center"
          class="mt-2 mb-3"
        >
          <v-col cols="10" md="5">
            <v-card color="blue">
              募集側チーム
            </v-card>
            <div
              class="text-h4 mt-2"
            >
              <router-link :to="`/team/${recruiting_teamId}`" class="no-underline" style="color: #f8f52f;">{{ recruiting_team }}</router-link>
            </div>
            <v-divider color="blue"></v-divider>
            <v-img
              class="mt-4"
              :src="image_rec"
            ></v-img>
            <div
              class="text-h6 mt-2"
              style="color: rgb(0, 80, 255);"
            >
              リーダー：{{ recruiting_cap }}
            </div>
          </v-col>
          <v-col cols="10" md="5">
            <v-card color="red">
              参加側チーム
            </v-card>
            <div
              class="text-h4 mt-2"
              style="color: #f8f52f;"
            >
            <router-link :to="`/team/${participating_teamId}`" class="no-underline" style="color: #f8f52f;">{{ participating_team }}</router-link>
            </div>
            <v-divider color="red"></v-divider>
            <v-img
              class="mt-4"
              :src="image_par"
            ></v-img>
            <div
              class="text-h6 mt-2"
              style="color: rgb(255, 50, 50);"
            >
              リーダー：{{ participating_cap }}
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-3"></v-divider>
        <h2 class="text-h6 ma-0" style="color: #f8f52f;">マップローテーション</h2>
        <p class="mb-4 mt-1 text-medium-emphasis text-body-2">
          <span class="text-medium-emphasis text-body-1">{{ map_1 }} ⇒ {{ map_2 }} ⇒ {{ map_3 }} ⇒ {{ map_4 }}</span><br><br>
          (必ずしもこのマップで対戦を行わなければいけないわけではありません。<br>マップ選択に迷った場合にご利用ください。)
        </p>
        <v-divider class="mb-3"></v-divider>
        <h2 class="text-h6 ma-0" style="color: #ff0000;">※注意事項</h2>
        <p class="mb-4 mt-1 text-medium-emphasis text-body-2">
          もしゲーム内でパーティ募集が立っていない・相手チームがパーティに参加してこない場合は、<br>
          お手数ですが、相手チームのリーダーにtellをお願いします。<br>
          tellが届かない、数分待っても返事がない場合は対戦終了ボタンを押して次の対戦に移ってください。
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-end">
          <v-btn
            class="neon-btn"
            outlined
            @click="finishMatch"
          >
            対戦終了
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- マッチング成功時のダイアログ -->

    <!-- 相手が対戦中止を選んだ時に表示されるダイアログ -->
    <v-dialog
      v-model="dialog_error"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="error"
          icon="mdi-close-circle"
          size="112"
        >mdi-close-circle</v-icon>
        <h2 class="error-text mb-6">Error</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          対戦相手のチームが対戦を辞退しました。<br>
          「終了」ボタンを押して次の対戦に移ってください。
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-center">
          <v-btn
            class="neon-cancel-btn"
            width="90"
            @click="cancelMatch"
          >
            終了
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- 相手が対戦中止を選んだ時に表示されるダイアログ -->

  </v-main>
</template>
<script>

export default {
  data() {
    return {
      count: false,
      timeoutId: null,
      dialog: true,
      dialog_success: false,
      dialog_error: false,
      match_id: null,
      recruiting_team: null,
      participating_team: null,
      recruiting_teamId: null,
      participating_teamId: null,
      recruiting_cap: null,
      participating_cap: null,
      image_rec: process.env.VUE_APP_S3_ENDPOINT + "icons/team_default.jpg",
      image_par: process.env.VUE_APP_S3_ENDPOINT + "icons/team_default.jpg",
      map_1: null,
      map_2: null,
      map_3: null,
      map_4: null,
      intervalId: null,
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '60%'
        case 'md': return '45%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },

    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '80%'
        case 'md': return '80%'
        case 'lg': return '70%'
        case 'xl': return '60%'
      }
      return 0
    },
  },

  methods: {

    // 相手が部屋に入ってくるのを待つ
    waitOpponent() {
      this.$sendRequest("get", "/matches_create/")
      .then((response) => {
        this.match_id = response.data.id
        // 相手が入ってきた場合の処理(両チームが承認しているマッチが返ってきた)
        if (response.data.accepted_recruiting && response.data.accepted_participating) {
          // タイマーをストップ
          this.count = false
          clearTimeout(this.timeoutId);
          this.clearInterval()
          // 募集側チームの情報を取得
          this.recruiting_team = response.data.recruiting_team.name
          this.recruiting_teamId = response.data.recruiting_team.id
          if (response.data.recruiting_team.image) {
            this.image_rec = process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT + response.data.recruiting_team.image
          }
          this.$sendRequest("get", "/captain_info/" + response.data.recruiting_team.id)
          .then((response) => {
            this.recruiting_cap = response.data.name
          })
          // 参加側チームの情報を取得
          this.participating_team = response.data.participating_team.name
          this.participating_teamId = response.data.participating_team.id
          if (response.data.participating_team.image) {
            this.image_par = process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT + response.data.participating_team.image
          }
          this.$sendRequest("get", "/captain_info/" + response.data.participating_team.id)
          .then((response) => {
            this.participating_cap = response.data.name
          })
          // マップ情報をJsonに書き換える
          let unicodeString = response.data.map_order
          let maps = JSON.parse(unicodeString);
          this.map_1 = maps[0]
          this.map_2 = maps[1]
          this.map_3 = maps[2]
          this.map_4 = maps[3]
          this.$nextTick(() => {
            this.dialog = false
            this.dialog_success = true
          })
        }
      })
      .catch(() => {
        // タイマーをストップ
        this.count = false
        clearTimeout(this.timeoutId);
        this.clearInterval()
        this.dialog = false;
        this.dialog_error = true;
      })
    },

    // 定期的にリクエストを送るのをやめる
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    // マッチング後に対戦をキャンセル
    cancelMatch() {
      this.$sendRequest("delete", "/matches_delete/" + this.match_id)
      .then(() => {
        this.$router.push('/free_match')
      })
      .catch(() => {
        this.$router.push('/free_match')
      })
    },

    // 対戦を終了
    finishMatch() {
      this.$sendRequest("put", "/matches_delete/" + this.match_id)
      .then(() => {
        this.$router.push('/free_match')
      })
    },

  },

  mounted: function() {
    document.title = "C.C.Community - フリー対戦";
    // アクセスした人がチームに属していてかつそのリーダーかどうか確認
    if (!this.$store.state.team || this.$store.state.status != "captain") {
      this.$router.push('/free_match')
    }

    // 5000ミリ秒（5秒）ごとにcreateMatchを呼び出す
    this.intervalId = setInterval(this.waitOpponent, 5000);

    // 1分半経っても相手が入ってこなかったらキャンセル
    this.count = true;
    this.timeoutId = setTimeout(() => {
      if (this.count) {
        this.clearInterval()
        this.dialog = false;
        this.dialog_error = true;
      }
    }, 90000);

  },

  beforeDestroy() {
    this.clearInterval();  // コンポーネントが破棄される前にタイマーをクリアする
  },
}
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
</style>