<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Free Match
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-row class="pa-0 mt-3">
          <v-col cols="12" md="5" lg="4">
            <div
              class="mb-3 pa-2 custom-shape-background sub-title-text"
              style="background-color: #f8f52f; display: flex; align-items: center;"
            >
              フリー対戦とは
            </div>
            <v-card
              class="mx-auto mb-3"
              outlined
              dark
            >
              <p class="ma-3 text-medium-emphasis text-body-2" style="line-height: 1.5;">
                フリー対戦では、対戦に参加中のチームの中からランダムでマッチングを行います。<br>
                <span style="text-decoration: underline;"><span style="color: rgb(255, 30, 30)">参加条件</span>はチームメンバーが5人以上であること</span>のみです。<br>
                チームリーダーが「フリー対戦に参加する」ボタン(ルール説明の下にあります)を押すと、チームが対戦に参加中の状態になります。
              </p>
            </v-card>
            <div
              class="sub-title-text mb-3 pa-2 custom-shape-background"
              style="background-color: #f8f52f; display: flex; align-items: center;"
            >
              ルール
            </div>
            <v-card
              class="mx-auto mb-3"
              outlined
              dark
            >
              <h4
                style="color: rgb(255, 30, 30)"
                class="ma-3 mb-0"
              >
                ・ジョブ重複なし
              </h4>
              <h4
                style="color: rgb(255, 30, 30)"
                class="ma-3 mb-0 mt-0"
              >
                ・マップギミックON
              </h4>
              <p class="ma-3 mt-2 text-medium-emphasis text-body-2" style="line-height: 1.5;">
                上記2点がルールになります。<br>
                試合数等の決まりは特に存在せず、あくまでもチーム同士のマッチングのための機能ですので、
                マッチング後にお互いのチームで話し合って試合数やマップ選択を行ってください。
                <span style="text-decoration: underline;">(マップ選択に関しましては、当サイトのマップルーレットもご活用ください。)</span><br>
                上記ルールが違反された場合は直ちに対戦を中止し、仕切り直すか、解散して次の対戦に移ってください。
              </p>
            </v-card>
            <v-btn
              outlined
              block
              class="mb-2 neon-btn"
              v-if="this.$store.state.refresh && this.$store.state.status"
              @click="participateMatch"
            >
              フリー対戦に参加する
            </v-btn>

            <!-- チーム人数が足りずに対戦に参加できなかった場合に表示されるダイアログ -->
            <v-dialog
              v-model="dialog_error"
              :width="cardWidth"
              persistent
            >
              <v-card
                dark
                elevation="5"
                class="pa-4 text-center mx-auto"
              >
                <v-icon
                  class="mb-5"
                  color="error"
                  size="112"
                >mdi-close-circle</v-icon>
                <h2 class="error-text mb-6">Error!</h2>
                <p class="mb-4 text-medium-emphasis text-body-2">
                  あなたのチームはチームメンバーが5人未満のため、対戦に参加できませんでした。<br>
                  チームメンバーを集めてからもう一度お試しください。
                </p>
                <v-divider class="mb-4"></v-divider>
                <div class="text-end">
                  <v-btn
                    class="neon-cancel-btn"
                    @click="dialog_error=false"
                  >
                    閉じる
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
            <!-- チーム人数が足りずに対戦に参加できなかった場合に表示されるダイアログ -->

            <!-- 対戦が終わってない状態で次に移ろうとした場合に表示されるダイアログ -->
            <v-dialog
              v-model="dialog_warning"
              :width="cardWidth"
            >
              <v-card
                dark
                elevation="5"
                class="pa-4 text-center mx-auto"
              >
                <v-icon
                  class="mb-5"
                  color="warning"
                  icon="mdi-alert-circle"
                  size="112"
                >mdi-alert-circle</v-icon>
                <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
                <p class="mb-4 text-medium-emphasis text-body-2">
                  現在進行中の対戦が存在します。<br>
                  現在進行中の対戦画面に戻る場合は「対戦に復帰」ボタンを、<br>
                  現在の対戦を終了して次の対戦に移る場合は「次の対戦に移る」ボタンを押してください。
                </p>
                <v-divider class="mb-4"></v-divider>
                <v-row justify="center">
                  <v-col>
                    <v-btn
                      class="neon-btn"
                      @click="returnToMatch"
                    >
                      対戦に復帰
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      class="neon-cancel-btn"
                      @click="nextMatch"
                    >
                      次の対戦に移る
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
            <!-- 対戦が終わってない状態で次に移ろうとした場合に表示されるダイアログ -->

          </v-col>
          <v-col cols="12" md="7" lg="8">
            <MatchList />
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import store from '../store';
import MatchList from '../components/MatchList.vue';

export default {
  components: {
    MatchList
  },

  data() {
    return {
      dialog_error: false,
      dialog_warning: false,
      progress_match_id: null,
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '75%'
        case 'md': return '50%'
        case 'lg': return '55%'
        case 'xl': return '40%'
      }
      return 0
    },
  },

  methods: {

    // フリー対戦に参加する
    participateMatch() {
      // チーム人数確認
      this.$sendRequest("get", "/member_list/" + this.$store.state.team.id)
      .then((response) => {
        // 5人以上なら対戦に参加できる
        if (response.data.length >= 5) {
          // 現在進行中のマッチがないか確認
          this.$sendRequest("get", "/matches_create/")
          // あれば警告を出す
          .then((response) => {
            this.progress_match_id = response.data.id
            this.dialog_warning = true
          })
          // エラーが返ってきた=マッチがないので次に進む
          .catch(() => {
            this.$sendRequest("put", "/teams/", {
              participating: true
            }).then((response) => {
              store.commit('setTeam', response.data)
              this.$nextTick(() => {
                this.$router.push('/waiting')
              })
            }).catch(() => {
              alert('通信エラーが発生しました。ページを再読み込みします。')
              location.reload();
            })
          })
          // 5人未満の場合エラーを出す
        } else if (response.data.length < 5) {
          this.dialog_error = true;
        }
      })
    },

    // 対戦に復帰
    returnToMatch() {
      this.$sendRequest("post", "/matches_accept/" + this.progress_match_id)
      this.$nextTick(() => {
        this.$router.push('/in_match')
      })
    },

    // 次の対戦に移る
    nextMatch() {
      this.$sendRequest("delete", "/matches_delete/" + this.progress_match_id)
      .then(() => {
        this.$sendRequest("put", "/teams/", {
          participating: true
        }).then((response) => {
          store.commit('setTeam', response.data)
          this.$nextTick(() => {
            this.$router.push('/waiting')
          })
        })
      })
    },

  },

  // トークンが切れてる時のみ挙動がおかしい
  mounted() {
    document.title = "C.C.Community - フリー対戦";
    window.scrollTo(0, 0);
    if (this.$store.state.refresh) {
      axios
        // 規制の有無を確認
        .get("/myteam/" + this.$store.state.id)
        .then((response) => {
          // TeamUserからユーザー情報を取得し、規制されているユーザーであればStatusとTeamをnullにする(規制時のための処理)
          if (response.data[0].member.regulation) {
            this.$store.commit('setRegulation', true)
            this.$store.commit('removeStatus')
            this.$store.commit('removeTeam')
          }
          // リーダーかどうか確認して、リーダーだったら保存(規制時のための処理)
          if (response.data[0].member_status === 'captain') {
            this.$store.commit('setStatus', response.data[0].member_status)
          }
        })
    }
    if (this.$store.state.refresh && this.$store.state.status === 'captain') {
      this.$sendRequest("get", "/accepted_match/")
      .then(() => {
        alert('対戦が終了していません。対戦ページに移動します。');
        this.$router.push('/in_match')
      })
    }
  },
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
  /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
</style>