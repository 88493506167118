<template>
  <v-main>
    <v-dialog
      v-model="dialog"
      :width="cardWidth"
      persistent
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <h2 class="title-text mb-4 mt-2" style="color: #f8f52f;">Waiting</h2>
        <v-progress-linear
          color="#35b3fc"
          indeterminate
        ></v-progress-linear>
        <p class="mb-4 mt-4 text-medium-emphasis text-body-2">
          対戦相手を探しています。<br>
          対戦相手が見つかるまでこのブラウザタブは開いたままでお待ちください。<br>
          このページを閉じる際はブラウザの戻るボタンではなく、下記の「参加取り消し」ボタンを押してください。
        </p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-center">
          <v-btn
            class="neon-cancel-btn"
            outlined
            @click="stopMatch"
          >
            参加取り消し
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_success"
      :width="cardWidth"
      persistent
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <h2 class="title-text mb-4" style="color: #f8f52f;">Success!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          対戦相手が見つかりました！<br>
          下記の「対戦に移る」ボタンを押して次の画面に進んでください。<br>
          このページを閉じる際はブラウザの戻るボタンではなく、下記の「辞退」ボタンを押してください。<br>
          (「辞退」ボタンは15秒経過すると選択可能となります。)<br>
          <span style="color: rgb(255, 20, 20)">※1分以内に選択されなかった場合、対戦不成立となりますのでご注意ください。</span>
        </p>
        <v-progress-circular
          :size="80"
          :width="10"
          :value="value"
          color="#f8f52f"
        >
          {{ remainingTime }}
        </v-progress-circular>
        <v-divider class="mb-4 mt-4"></v-divider>
        <v-row>
          <v-col>
            <v-btn
              class="neon-btn"
              outlined
              @click="acceptMatch"
            >
              対戦に移る
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="cancelMatchBtn"
              :disabled="disableDeclineButton"
            >
              辞退
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import store from '../store';

export default {
  data() {
    return {
      dialog: true,
      dialog_success: false,
      isLoading: true,
      intervalId: null,
      count: false,
      match_id: null,
      totalTime: 60, // Total time for countdown in seconds
      remainingTime: 60, // Remaining time
      value: 100, // For progress circular
      disableDeclineButton: false, // ボタンが押せるか押せないかのトリガー
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '60%'
        case 'md': return '45%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },
  },

  methods: {

    // カウントダウンタイマー
    startCountdown() {
      const intervalId = setInterval(() => {
        this.remainingTime -= 1;
        this.value = (this.remainingTime / this.totalTime) * 100;

        if (this.remainingTime <= 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    },

    // 定期的にリクエストを送るのをやめる
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    // マッチング後に対戦をキャンセル
    cancelMatchBtn() {
      this.$sendRequest("delete", "/matches_delete/" + this.match_id)
      .then(() => {
        this.$router.push('/free_match')
      })
    },

    cancelMatch() {
      this.$sendRequest("delete", "/matches_delete/" + this.match_id)
      .then(() => {
        alert("1分経過しましたが参加の可否が選択されませんでしたので、マッチングはキャンセルされました。")
        this.$router.push('/free_match')
      })
    },

    // Matchを作成する
    createMatch() {
      this.$sendRequest("post", "/matches_create/")
      .then((response) => {
        this.match_id = response.data.id
        this.dialog = false
        this.dialog_success = true

        // dialog_successが開いたときにボタンを非活性にする
        this.disableDeclineButton = true;

        // 15秒後に辞退ボタンを活性化
        setTimeout(() => {
          this.disableDeclineButton = false;
        }, 15000);  // 15000ミリ秒 = 15秒

        this.clearInterval()  // マッチングが成功したら定期的なリクエストをキャンセル

        // カウントダウンを開始
        this.count = true;
        this.startCountdown();

        // 承認ボタンが押されなければ1分後にマッチをキャンセル
        setTimeout(() => {
          if (this.count) {
            this.cancelMatch();
          }
        }, 60000);  // 60000ミリ秒 = 1分
      })
    },

    // 対戦に移る
    async acceptMatch() {
      this.count = false;
      await this.$sendRequest("post", "/matches_accept/" + this.match_id);
      this.$nextTick(() => {
        this.$router.push('/in_match');
      });
    },

    // やめる
    stopMatch() {
      this.$sendRequest("put", "/teams/", {
        participating: false
      }).then((response) => {
        store.commit('setTeam', response.data)
        this.$nextTick(() => {
          this.$router.push('/free_match')
        })
      })
    },

    // ページを離れる時に行う
    async beforeRouteLeave(to, from, next) {
      try {
        const response = await this.$sendRequest("put", "/teams/", {
          participating: false
        });
        store.commit('setTeam', response.data);
      } catch (error) {
        // エラーハンドリング
      }
      next();
    },

  },

  mounted: function() {
    document.title = "C.C.Community - フリー対戦";
    if (!this.$store.state.team || this.$store.state.status != "captain") {
      this.$router.push('/free_match')
    }
    this.$sendRequest("get", "/teams/")
    .then((response) => {
      if (response.data.participating === false) {
        this.$router.push('/free_match')
      }
    })
    this.intervalId = setInterval(this.createMatch, 15000);  // 15000ミリ秒（15秒）ごとにcreateMatchを呼び出す
  },

  beforeDestroy() {
    this.clearInterval();  // コンポーネントが破棄される前にタイマーをクリアする
    this.$sendRequest("put", "/teams/", {
        participating: false
      }).then((response) => {
        store.commit('setTeam', response.data)
        })
  },
}
</script>