<template>

  <v-app class="gradient-background">
    <Header/>
    <!-- 本コンテンツ -->
    <router-view></router-view>
    <!-- 本コンテンツ -->
    <Footer/>
  </v-app>

</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: {
    Header,
    Footer,
  },

  metaInfo: {
    title: 'C.C.Community',
    meta: [
      {
        name: 'description',
        content: 'C.C.Communityは、FF14のPvPコンテンツ、Crystaliine Conflict(クリコン)のチーム戦のマッチング支援サイトです。登録後、チームを作成して対戦に申請することで、同じ時間帯に申請しているほかのチームとランダムでマッチングして簡単に対戦相手を見つけることができます。'
      }
    ]
  }
}
</script>

<style scoped>
.gradient-background {
  background: linear-gradient(135deg, #f915aa 0%, #06aad3 100%);
  overflow: hidden;
}
</style>