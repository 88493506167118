import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// export default new Vuetify({});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  },
  typography: {
    fontFamily: 'Noto Sans JP, sans-serif', // ここでフォントを設定
  },
});
