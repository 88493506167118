<template>
  <v-main class="mb-5">
    <v-row
      justify="center"
      class="starry-sky pa-0 ma-0"
    >
      <div v-for="i in 50" :key="i"
        class="star"
        :style="{ backgroundColor: ['#ffd700', '#c0c0c0'][Math.floor(Math.random() * 2)], width: '2px', height: '2px', top: Math.random() * 100 + '%', left: Math.random() * 100 + '%' }"
      ></div>
      <v-col class="d-flex justify-center">
        <div style="width: 100%; display: flex; justify-content: center;">
          <v-img
            :src="imageUrl"
            max-width="442"
            max-height="260"
          ></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="main-content"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          C.C.Community
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-card dark class="mt-3">
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            C.C.Communityは、FINAL FANTASY XIVにおけるPvPコンテンツの1つ、Crystalline Conflictのチームでの対戦、交流を目的としたWEBサービスです。
            登録することで、チームの管理や、気軽にチーム対戦を行うことができるフリー対戦の機能を使用できます。<br>
            フリー対戦では、FF14ゲーム内のカジュアルマッチのように、<span style="text-decoration: underline;">事前に予定を決めることなく好きな時間に</span>チーム対戦ができます。<br><br>
            ユーザー登録は<span @click="TwitterLogin" class="clickable-text">こちら</span>から。<br>
            登録にはTwitterアカウントが必要です。<a href="https://twitter.com/" class="no-underline clickable-text">Twitterへの登録はこちらから</a>。<br>
            <span style="color: rgb(255, 30, 30)">連携によって当サイトに許可される権限は閲覧のみでございますので、勝手に連携したアカウントを利用してツイートやリツイート等を行うことは一切ございません。</span>
          </v-card-text>
        </v-card>

        <v-row justify="center">
          <v-col cols="12" lg="7">
            <div
              class="mt-8 mb-3 pa-2 custom-shape-background sub-title-text clickable-div"
              @click="$router.push('/free_match')"
            >
              フリー対戦状況
            </div>
            <MatchList />
          </v-col>
          <v-col cols="12" lg="5">
            <div
              class="mt-8 mb-3 pa-2 custom-shape-background sub-title-text clickable-div"
              @click="$router.push('/team_search')"
            >
              メンバー募集中のチーム
            </div>
            <RecruitingTeamList />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import MatchList from '../components/MatchList.vue';
import RecruitingTeamList from '../components/RecruitingTeamList.vue';

export default {
  components: {
    MatchList,
    RecruitingTeamList
  },
  data() {
    return {
      Logo_img: process.env.VUE_APP_S3_ENDPOINT + 'icons/サイト名ロゴ-removebg-preview.png',
      users: [],
      imageUrl: process.env.VUE_APP_S3_ENDPOINT + 'icons/ug305Ob9UkNFnABSTwZddPDDA0.png.webp',
      test: [],
    };
  },

  mounted: function () {
    document.title = "C.C.Community - ホーム";
    window.scrollTo(0, 0);
    if (this.$store.state.user != null) {
      this.users = this.$store.state.user
    }
  },

  methods: {
    TwitterLogin: function(){
      if (this.$store.state.refresh) {
        this.$router.push('/user/' + this.$store.state.id)
      } else {
        window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"
      }
    },
  }

};
</script>

<style>
  .custom-line-height {
    line-height: 1.8; /* 行間を1.8に設定 */
  }

  .no-underline {
    text-decoration: none;
  }

  .clickable-text {
    color: 	#0066c0;
    cursor: pointer;
  }

  .custom-shape-background {
    background: #f8f52f; /* 任意の背景色 */
  ; /* 任意の背景色 */
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
    /* clip-path の形状を変更して好みの形状にすることができます */
    overflow: hidden;
  }

  .starry-sky {
    background-image: linear-gradient(to bottom, #00063a, #00303bea);
    position: relative;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
  }

  .star {
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    animation: shimmer 1.5s infinite;
  }

  @keyframes shimmer {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

/* スマホ以外 */
@media screen and (min-width: 415px) {
  .main-content {
    margin-top: 10px !important;
  }
}

/* スマホ */
@media screen and (max-width: 414px) {
  .main-content {
    margin-top: 10px !important;
  }
}

.clickable-div {
  cursor: pointer;
}
</style>