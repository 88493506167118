<template>
    <v-main>
      <v-row style="height: 100%;" align-content="center">
        <v-col align="center">
          <v-card :width="cardWidth" elevation="5" dark>
            <v-row class="justify-center">
              <v-col cols="8" sm="6">
                <v-row justify="center">
                  <v-card-title class="title-text">Sign Up</v-card-title>
                </v-row>
                <v-divider color="#5A49B5" style="border-width: 1.5px;"></v-divider>
              </v-col>
            </v-row>
            <v-row class="justify-center align-center" dense no-gutters>
              <v-col cols="10" sm="8">
                <p class="mt-10 font-weight-medium">「The Lodestone」マイキャラクターページURLの末尾の数字を半角で入力してください(URLコピペでも可)</p>
                <v-form>
                  <v-text-field
                    v-model="lodestone_numbers"
                    inputmode="numeric"
                    label="入力例: 12345678"
                    @input="keyUpHalfSizeRestriction"
                    :rules="rules"
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="4" sm="3" md="2">
                <v-btn
                  tile
                  outlined
                  width="100%"
                  @click="checkUser()"
                  :disabled="isSendDisabled"
                >
                  確認
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
</template>

<script>
import axios from 'axios'

export default{
  data(){
    return{
      select: null,
      lodestone_numbers: '',
      id: location.pathname.replace('/user_register/',''),
      rules: [],
      users: [],
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '75%'
        case 'md': return '50%'
        case 'lg': return '55%'
        case 'xl': return '40%'
      }
      return 0
    },
    isSendDisabled() {
      return !(
        // 下記が満たされていれば確認ボタンが押せる
        this.lodestone_numbers
        );
    },
  },

  mounted: function(){
    document.title = "C.C.Community - ユーザー登録";
    if (this.$store.state.refresh) {
      this.$router.push('/')
    }
  },

  methods: {
    // 入力されたURLから末尾の数字のみを抽出する
    keyUpHalfSizeRestriction: function() {
      if(this.lodestone_numbers){
        this.lodestone_numbers = this.lodestone_numbers.replace(/[^0-9]/g,'');
      }
    },

    // キャラクターを審査する
    checkUser: function(){
      axios
        // 既に登録済みのキャラクターかどうかを確認
        .get("/users/" + Number(this.lodestone_numbers))
        // レスポンスが200番台で返ってきた（既に登録されていた）
        .then(() => {
            this.rules = ['このキャラクターは既に登録されています']
        })
        // レスポンスが400番台で返ってきた（登録されていなかった）=> 次に進める
        .catch(() => {
          axios
            // lodestoneの公式ページにそのキャラクターが存在するか確認
            .get("/check_ldst_users/" + Number(this.lodestone_numbers))
            .then((response) => {
              // 存在する正しいキャラクターだった場合
              if (response.data) {
                // 次の最終確認画面へ遷移させる
                this.$router.push({
                  name: 'CheckRegister',
                  query: {
                    id: this.id,
                    ldst_id: this.lodestone_numbers
                  }
                })
              }
            })
            // lodestoneに存在しないキャラクターだった場合（入力した数字に誤りがある等）
            .catch(() => {
              // エラーメッセージを表示し、再入力を求める
              this.rules = ['このキャラクターは存在しません']
            })
        })
    },
  }
}
</script>
