<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-3"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Q&A
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          ユーザー登録について
        </div>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            ユーザー登録方法がわかりません
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            画面右上の丸いユーザーのアイコンを押していただくとTwitter認証画面に移動しますので、認証をしていただくと当サイトのユーザー作成を行うことができます。<br>
            Twitter認証については、当サイトは閲覧権限しか持ちませんので勝手にツイートやリツイートを行うことはありません。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            C.C.Communityを退会したいです
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            そ、そんなぁ…悲しいです…。<br>
            とまぁ冗談は置いておいて、<router-link to="/contact" class="no-underline">お問い合わせページ</router-link>から退会することができます。<br>
            退会しても再開するのは簡単なのでいつでも戻ってきてくださいね。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            退会したけど、やっぱり再開したいです
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            初期登録時と同じ手順で再登録する形で再開することができます。ただし、過去の記録等は全て初期化されてしまいますのでご注意ください。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            自分のキャラクターで登録しようとすると、「既に登録済みです。」と表示されます。
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            他人によってキャラクターが登録されてしまっている可能性があります。作りたてのキャラクターで大丈夫なので代わりのキャラクターで登録後、お問い合わせより報告をお願いします。すぐに対応させていただきます。
          </p>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          チーム作成について
        </div>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            チームを作成したけど、メンバーの集め方が分かりません
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            当サービスで想定しているチーム募集は、ゲーム内のパーティ募集やLodestoneの日記、Twitter等を利用したものです。<br>
            当サイト内でも、チームの状態をチーム募集中にし、チームの紹介文欄に募集しているメンバーの条件を記載しておくことで、チームを探しているユーザーがチーム検索を行った際に見つけやすくなります。<br>
            チーム検索から募集中のチームを見つけて、LodestoneやTwitterの方に行き、詳細な募集内容を確認するといった流れを想定しています。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            チーム名を変更したい
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            チーム名は現状変更できない仕様となっております。チーム名を変更したい場合は、1度チームを解散して新しくチームを作成し直してください。
          </p>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          フリー対戦について
        </div>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            対戦相手とマッチングしてパーティ募集を行っていますが、相手が来ません<br>
            または、参加側になったのですが、募集が出ていません
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            お手数ですが、まずは相手のチームのリーダーにTellで連絡をお願いします。
            返事がない、Tellが届かない場合は、対戦終了のボタンを押して次の対戦に移っていただいて構いません。<br>
            何度も同じチームで起きる場合は、<router-link to="/contact" class="no-underline">お問い合わせページ</router-link>よりご報告いただけると幸いです。
          </p>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          暴言や煽り行為について
        </div>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            対戦中に煽られました
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            煽り行為に関しては<router-link to="/contact" class="no-underline">お問い合わせページ</router-link>よりご報告ください。
            その際、当サイトの方では対戦のログ等は当然見ることができないので、証拠となるような録画等も保存しておくようにお願いします。
            後ほど録画やスクリーンショットの確認のために、TwitterのDMにてご連絡させていただくことがございます。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            Twitter上で暴言を吐かれました
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            Twitterでの発言云々は、不正の裏付け等でない限り直接確認することはございませんので、予めご了承ください。<br>
            当該アカウントのツイートを見ない、ブロックする等、個々での対応をお願いします。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            配信で暴言を吐かれました
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            配信での発言云々は、不正の裏付け等でない限り直接確認することはございませんので、予めご了承ください。<br>
            当該アカウントの配信を見ない等、個々での対応をお願いします。
          </p>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          規制について
        </div>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            煽り行為を報告したプレイヤーが未だに普通に利用していますが…規制したか教えてもらうことはできませんか？
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            規制したかどうかは報告者にはお伝えしていません。
            対戦中のログ等を確認できないため、1人の報告で即規制に至ることは稀です。<br>
            複数人の報告や確かな証拠があり、悪質だと判断した場合に規制を行います。予めご了承ください。
          </p>
        </v-card>

        <v-card dark>
          <div class="text-body-1 font-weight-bold pa-2 custom-line-height">
            規制された理由を教えてください
          </div>
          <v-divider></v-divider>
          <p class="text-body-2 pa-2 custom-line-height">
            こちらから迷惑プレイヤーに規制理由はお伝えしていません。<br>
            規制理由から報告にご協力いただいたユーザー様を特定したり、その結果他のユーザー様を報告者と勘違いされて迷惑がかかる可能性があるからです。予めご了承ください。
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
    document.title = "C.C.Community - Q&A";
  },
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
; /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
.custom-line-height {
  line-height: 1.8;  /* 行間を1.8に設定 */
}
.no-underline {
  text-decoration: none;
}

</style>