<template>
    <v-main>
      <v-row style="height: 100%" align="center">
        <v-col align="center">
          <v-card :width="cardWidth" elevation="5" dark>
            <v-row class="justify-center">
              <v-col cols="8" sm="6">
                <v-row justify="center">
                  <v-card-title class="title-text">Sign Up</v-card-title>
                </v-row>
                <v-divider color="#5A49B5" style="border-width: 1.5px;"></v-divider>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="8" sm="8">
                <div class="text-center font-weight-bold text-h5">
                  このキャラクターでよろしいですか？
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10" sm="8">
                <v-card
                  class="mx-auto"
                  max-width="344"
                  outlined
                >
                  <v-list-item>
                    <v-list-item-avatar
                      tile
                      size="80"
                    >
                      <v-img
                        :src="this.ldst_users.face"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 ma-2" :style="{ 'word-wrap': 'break-word' }">
                        {{ this.ldst_users.name }}
                      </v-list-item-title>
                      <v-list-item-title class="ma-2">
                        {{ this.ldst_users.world }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="justify-center pt-8 pb-8" dense no-gutters>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-col class="pl-2" cols="4" sm="3">
                <v-btn
                  class="neon-btn"
                  outlined
                  block
                  :loading="submitted"
                  @click="createUser()"
                  :disabled="disableDeclineButton"
                >登録</v-btn>
              </v-col>

              <!-- 登録が失敗したときに表示するダイアログ -->
              <v-dialog
                v-model="dialog"
                :width="dialogWidth"
                persistent
              >
                <v-card
                  dark
                  elevation="5"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="error"
                    icon="mdi-check-circle"
                    size="112"
                  >mdi-close-circle</v-icon>
                  <h2 class="error-text mb-6" style="color: #f8f52f;">Error</h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    ユーザー登録に失敗しました。
                    <br>
                    お手数ですが、下記のボタンよりもう一度Twitter認証を行ってください。
                  </p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="text-center">
                    <v-btn
                      class="text-none"
                      color="primary"
                      rounded
                      variant="flat"
                      width="110"
                      @click="TwitterLogin"
                    >
                      Twitter認証
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <!-- 登録が失敗したときに表示するダイアログ -->

              <v-spacer></v-spacer>
              <v-col class="pr-2" cols="4" sm="3">
                <v-btn
                  class="neon-cancel-btn"
                  outlined
                  block
                  :loading="submitted"
                  @click="backPage()"
                  :disabled="disableDeclineButton"
                >変更</v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
</template>
<script>
import store from '../store';
import axios from "axios";

export default {
  data() {
    return {
      users: [],
      id: this.$route.query.id,
      ldst_numbers: this.$route.query.ldst_id,
      ldst_users: [],
      dialog: false,
      submitted: false,
      disableDeclineButton: true,
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '75%'
        case 'md': return '50%'
        case 'lg': return '55%'
        case 'xl': return '40%'
      }
      return 0
    },
    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '55%'
        case 'md': return '45%'
        case 'lg': return '35%'
        case 'xl': return '28%'
      }
      return 0
    },
  },

  mounted: function() {
    document.title = "C.C.Community - ユーザー登録";
    if (this.$store.state.refresh) {
      this.$router.push('/')
    }
    axios
      // lodestoneからキャラクター情報を取得する（前ページでキャラクターの存在は確認している）
      .get("/check_ldst_users/" + this.ldst_numbers)
      .then((response) => {
        this.ldst_users = response.data
        this.disableDeclineButton = false
      })
      .catch(() => {
        // ユーザー登録ページに戻す
        this.$router.push('/user_register')
      })
  },

  methods: {
    // 前のページに戻る
    backPage: function() {
      this.$router.push('/user_register')
    },

    TwitterLogin: function() {
      axios
        .get("/twitter_oauth/")
        .then((window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"))
    },

    // キャラクター登録をする
    createUser: function() {
      this.submitted = true;
      axios
        // LodestoneUser情報とTwitterUser情報をCostomUser(本サイトのユーザー情報)に保存する
        .post("/users_create/" + this.id, {
          ldst_id: this.ldst_numbers,
          password: this.id,
          name: this.ldst_users.name,
          face: this.ldst_users.face,
          world: this.ldst_users.world,
        })
        // 保存が成功したらログイン処理に入る
        .then(() => {
          axios
            // Twitterトークンからユーザーを検索し存在すればログインできる
            .get("/check_users/" + this.id)
            // レスポンスが200番台で返ってきた(ログイン成功)
            .then((response) => {
              this.users = response.data.user
              // 各情報をストアに保存
              store.commit('setID', this.users.id)
              store.commit('setName', this.users.name)
              store.commit('setFace', this.users.face)
              store.commit('setWorld', this.users.world)
              store.commit('setTeam', this.users.team)
              store.commit('setAccess', response.data.access)
              store.commit('setRefresh', response.data.refresh)
              this.$router.push('/user/' + this.$store.state.id)

              alert('ユーザーが正常に作成されました。');
            })
            // レスポンスが400番台で返ってきた(ログイン失敗)
            .catch(() => {
              this.$router.push('/user_register')
            })
        })
        .catch(() => {
          this.dialog = true
        })
    },
  }
}
</script>
