<template>
  <v-main>
    <v-row style="height: 100%;" justify="center" class="mt-8 mb-8">
      <v-col cols="11" md="8" xl="6">
        <v-row>
          <v-col cols="10">
            <div
              class="title-text"
            >
              {{ this.teams.name }}
            </div>
          </v-col>
          <TeamEdit
            :teams="teams"
            :team_image="team_image"
            :captain="captain"
          ></TeamEdit>
        </v-row>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>
        <v-row class="mt-1 pl-3" v-if="this.teams.recruiting_members === true">
          <div>
            <div
              class="text-h5 neon-border neon-text mt-3"
            >
              Seeking Team Members
            </div>
          </div>
        </v-row>
        <v-row class="mt-3">
          <v-col>
            <v-img
              :src="team_image"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="mb-5">

          <!-- 左側のカラム -->
          <v-col cols="12" md="5">
            <!-- チームメンバーリスト -->
            <div
              class="sub-title-text mb-3 pa-2 custom-shape-background"
              style=" display: flex; align-items: center;"
            >
              チームメンバー
            </div>
            <TeamMemberList
              :captain="captain"
              :members="members"
              :teams="teams"
            ></TeamMemberList>
            <!-- チームメンバーリスト -->
            <!-- レーティング対戦情報
            <div
              class="text-h5 mt-5 mb-3 pa-2 custom-shape-background"
              style="color: #5A49B5; display: flex; align-items: center;"
            >
              レーティング対戦
            </div>
            レーティング対戦情報 -->
          </v-col>
          <!-- 左側のカラム -->

          <!-- 右側のカラム -->
          <v-col cols="12" md="7">
            <!-- チーム紹介文 -->
            <div
              class="sub-title-text mb-3 pa-2 custom-shape-background"
              style="display: flex; align-items: center;"
            >
              チーム紹介
            </div>
            <v-card
              class="mx-auto mb-3 neon-card"
              outlined
              dark
              :style="{ minHeight: '100px', maxHeight: '200px', overflow: 'auto'}"
            >
              <pre style="white-space: pre-wrap;">{{ this.teams.introduction }}</pre>
            </v-card>
            <!-- チーム紹介文 -->
            <!-- 実績
            <div
              class="text-h5 mt-5 mb-3 pa-2 custom-shape-background"
              style="color: #5A49B5; display: flex; align-items: center;"
            >
              実績
            </div>
            実績 -->
          </v-col>
          <!-- 右側のカラム -->
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";
import TeamMemberList from "../components/TeamMemberList.vue";
import TeamEdit from "../components/TeamEdit.vue";

export default {
  components: {
    TeamMemberList,
    TeamEdit,
  },
  data() {
    return {
      teams: [],
      team_image: "",
      captain: [],
      members: [],
      member_names: [],
      team_id: location.pathname.replace("/team/", ""),
    };
  },

  // チームが存在しない場合はその旨を書いたダイアログを出したい
  mounted: function () {
    document.title = "C.C.Community - チームページ";
    window.scrollTo(0, 0);
    // チームと所属メンバーの情報を取得
    axios
      .get("/member_list/" + this.team_id)
      .then((response) => {
        // チーム情報を抽出
        this.teams = response.data[0]["team"];
        // チーム画像を取得
        if (this.teams.image) {
          this.team_image = process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT + this.teams.image;
        }
        // チームメンバーを振り分け
        for (let i = 0; i < response.data.length; i++) {
          // リーダーならcaptainに入れる
          if (response.data[i]["member_status"] === "captain") {
            this.captain = {
              "id": response.data[i]["member"]["id"],
              "name": response.data[i]["member"]["name"],
              "face": response.data[i]["member"]["face"],
            }
            // メンバーならmemberに入れる
          } else if (response.data[i]["member_status"] === "member" && response.data[i]["admission_status"] === "official") {
            this.members.push(
              {
                "id": response.data[i]["member"]["id"],
                "name": response.data[i]["member"]["name"],
                "face": response.data[i]["member"]["face"],
              }
            )
          }
        }
      })
      .catch(() => {
        this.$router.push('/')
      })
  },
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
; /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
.neon-text {
  font-family: 'Orbitron', sans-serif !important;
  color: #fc13c2;
  text-shadow:
    0 0 3px #fc3613,
    0 0 6px #fc3613,
    0 0 9px #fc3613,
    0 0 12px #fc3613,
    0 0 15px #fc3613;
}
.neon-border {
  display: inline-block;
  border: 2px solid #00bddf; /* 基本のボーダー */
  padding: 5px;
  box-shadow:
    0 0 2px #00bddf,
    0 0 4px #00bddf,
    0 0 6px #00bddf,
    0 0 8px #00dfc1,
    0 0 10px #00dfc1;
  background-color: black;
}
@keyframes glow {
  0% { text-shadow: 0 0 5px #00ff62, 0 0 10px #00ff62, 0 0 15px #00ff62, 0 0 20px #00ff62, 0 0 25px #00ff62; }
  50% { text-shadow: 0 0 10px #00ff62, 0 0 20px #00ff62, 0 0 30px #00ff62, 0 0 40px #00ff62, 0 0 50px #00ff62; }
  100% { text-shadow: 0 0 5px #00ff62, 0 0 10px #00ff62, 0 0 15px #00ff62, 0 0 20px #00ff62, 0 0 25px #00ff62; }
}
.cyberpunk-text {
  font-family: 'Orbitron', sans-serif !important;
  color: #f8f52f;
  font-size: 200%;
  font-weight: 300;
  letter-spacing: 2px;
  animation: glow 1.5s infinite alternate;
}

/* スクロールバー全体 */
::-webkit-scrollbar {
  width: 5px;
}

/* トラック（背景） */
::-webkit-scrollbar-track {
  background: #000;  /* ダーク背景 */
}

/* ハンドル（バー） */
::-webkit-scrollbar-thumb {
  background: linear-gradient(100deg, #00ff62, #f8f52f); /* 緑と黄色のグラデーション */
  border-radius: 10px; /* 角を丸く */
  box-shadow: 0 0 10px #fff,  /* 内側の白い光 */
              0 0 5px #00ff62,  /* 緑の光 */
              0 0 15px #00ff62,
              0 0 20px #00ff62,
              0 0 25px #00ff62,
              0 0 30px #00ff62,
              0 0 35px #00ff62;
}

/* ハンドル（バー）: Hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #00ff62, #f8f52f); /* グラデーション変更 */
}
</style>