<template>
  <div>
    <div
      class="neon-text neon-border mb-3"
    >
      {{ participatingTeamsCount }}チームがフリー対戦に参加中です。
    </div>
    <!-- 何チーム参加中か -->
    <v-card dark>
      <v-list v-if="matches.length" class="pb-0">
        <v-list-item-group>
          <div v-for="match in paginatedMatches" :key="match.id" class="ma-2 mt-1">
            <v-row class="mb-4 mb-md-2">
              <v-col cols="6">
                <router-link :to="`/team/${match.recruiting_team.id}`" class="no-underline">
                  <v-list-item-title class="team-name">
                    {{ match.recruiting_team.name }}
                  </v-list-item-title>
                </router-link>
                <img
                  :src="getRecruitingTeamImageUrl(match)"
                  alt="Team Image"
                  width="100%"
                  height="80%"
                >
              </v-col>
              <v-col cols="6">
                <router-link :to="`/team/${match.participating_team.id}`" class="no-underline">
                  <v-list-item-title class="team-name">
                    {{ match.participating_team.name }}
                  </v-list-item-title>
                </router-link>
                <img
                  :src="getParticipatingTeamImageUrl(match)"
                  alt="Team Image"
                  width="100%"
                  height="80%"
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
        class="pb-2 pt-2 neon-pagination"
        color="#1e1e1e"
      ></v-pagination>
    </v-card>
    <!-- 何チーム参加中か -->

  </div>
</template>

<script>

export default {

  data() {
    return {
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
      matches: [],
      no_image: process.env.VUE_APP_S3_ENDPOINT + "icons/team_default.jpg",
      participatingTeamsCount: 0,
      currentPage: 1,
      perPage: 10,
    };
  },

  computed: {
    paginatedMatches() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.matches.slice(start, end);
    },

    totalPages() {
      return Math.ceil(this.matches.length / this.perPage);
    },

  },

  methods: {
    // マッチを取得
    async fetchMatches() {
      try {
        let response = await fetch(this.apiEndpoint + '/match_list/');
        let data = await response.json();
        this.matches = data.matches || [];
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    },
    // マッチに参加しているチーム数を取得
    async fetchParticipatingTeamsCount() {
      try {
        let response = await fetch(this.apiEndpoint + '/participating_team/');
        let data = await response.json();
        this.participatingTeamsCount = data.count;
      } catch (error) {
        console.error("Error fetching participating teams count:", error);
      }
    },

    getRecruitingTeamImageUrl(match) {
      if (match && match.recruiting_team && match.recruiting_team.image) {
        return `${process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT}${match.recruiting_team.image}`;
      }
      return this.no_image;
    },

    getParticipatingTeamImageUrl(match) {
      if (match && match.participating_team && match.participating_team.image) {
        return `${process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT}${match.participating_team.image}`;
      }
      return this.no_image;
    }
  },

  mounted() {
    this.fetchMatches();
    this.fetchParticipatingTeamsCount();
  },
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
.team-name {
  color: #f8f52f;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 120%;
}
.neon-text {
  font-family: 'Orbitron', sans-serif !important;
  color: #f915aa;
  text-shadow:
    0 0 3px #fc3613,
    0 0 6px #fc3613,
    0 0 9px #fc3613,
    0 0 12px #fc3613,
    0 0 15px #fc3613;
}
.neon-border {
  display: inline-block;
  border: 2px solid #06aad3;
  padding: 5px;
  box-shadow:
    0 0 2px #06aad3,
    0 0 4px #06aad3,
    0 0 6px #06aad3,
    0 0 8px #00dfc1,
    0 0 10px #00dfc1;
  background-color: black;
}
</style>