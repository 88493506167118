<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Jobs
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>
      </v-col>
    </v-row>

  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      jobs: [],
    };
  },

  mounted() {
    document.title = "C.C.Community - ジョブ";
    window.scrollTo(0, 0);
    axios.get("/jobs/")
      .then((response) => {
        this.jobs = response.data
        // console.log(this.jobs)
      })
  },

}
</script>

<style scoped>
.job-name {
  font-family: 'Noto Sans JP', sans-serif !important;
  font-size: 200%;
}
</style>