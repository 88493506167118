<template>
  <div>
    <!-- ナビゲーションバー -->
    <v-navigation-drawer
      v-model="drawer"
      clipped
      class="drawer drawer-margin"
    >
      <v-list expand nav dense>
        <v-text-field
          v-if="isMobile"
          class="custom-width neon-glow ml-5 mb-3 mt-2"
          v-model="searchQuery"
          label="検索"
          solo
          append-icon="mdi-magnify"
          hide-details
          dense
          clearable
          dark
          @keyup.enter="onSearchInput(searchQuery)"
          @click:append="onSearchInput(searchQuery)"
        ></v-text-field>
        <v-list-item @click="ToHome()" class="ml-3" color="black">
          <v-list-item-icon class="mr-6">
            <v-icon style="color: #000000;">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title style="color: #000000;">ホーム</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :value="item.open"
          no-action
          no-arrow
          class="ml-1 custom-icon-color"
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-icon class="mr-6">
                <v-icon style="color: #000000;">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title style="color: #000000;" v-text="item.title"></v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
          >
          <v-list-item-content>
            <router-link
              v-if="child.action"
              :to="child.action"
              style="text-decoration: none;"
            >
              <v-list-item-title style="color: #000000;" v-text="child.title"></v-list-item-title>
            </router-link>
            <v-list-item-title v-else style="color: #000000;" v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- ナビゲーションバー -->

    <!-- ヘッダー -->
    <v-app-bar app clipped-left elevation="1" :fixed="true" class="gradient-background">
      <v-row align="center" dense no-gutters>
        <v-col> <!--cols="2" md="4"-->
          <v-row align="center" dense no-gutters>
            <v-col cols="1">
              <v-app-bar-nav-icon color="#f8f52f" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </v-col>
            <v-col class="ml-8 ml-lg-4 d-none d-xl-block d-lg-block d-md-block">
              <router-link to="/">
                <v-img
                  :src="Logo_img"
                  contain
                  class="header-image mt-2"
                ></v-img>
              </router-link>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="!isMobile" cols="8" sm="7" md="5">
          <v-text-field
            class="neon-glow ml-5 mb-3 mt-3"
            v-model="searchQuery"
            label="検索"
            solo
            append-icon="mdi-magnify"
            hide-details
            dense
            clearable
            dark
            @keyup.enter="onSearchInput(searchQuery)"
            @click:append="onSearchInput(searchQuery)"
          ></v-text-field>
        </v-col>

        <v-col v-if="isMobile" align="center">
          <router-link to="/">
            <v-img
              :src="Logo_img_sm"
              contain
              class="header-image-sm"
            ></v-img>
          </router-link>
        </v-col>

        <v-col class="d-flex justify-end"> <!-- cols="2" sm="3" md="4" -->
          <v-menu
            offset-y
            v-if="this.$store.state.id"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                fab
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  size="40"
                >
                  <UserFace></UserFace>
                </v-avatar>
              </v-btn>
            </template>
            <v-row dense no-gutters>
              <v-col>
                <v-sheet width="300" dark>
                  <v-list class="ma-0 pa-0">
                    <v-list-item class="pt-3 pb-3 pl-5 pr-0">
                      <v-list-item-avatar
                        size="40"
                      >
                        <UserFace></UserFace>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ this.$store.state.name }}
                        </v-list-item-title>
                        <v-list-item-title>
                          {{ this.$store.state.world }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item class="pl-7 pr-0" @click="ToMyPage()">
                      <v-list-item-icon class="mr-6">
                        <v-icon>mdi-account-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>マイページ</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="pb-2 pl-7 pr-0" @click="Logout()">
                      <v-list-item-icon class="mr-6">
                        <v-icon>mdi-exit-to-app</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>ログアウト</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-sheet>
              </v-col>
            </v-row>
          </v-menu>
          <v-menu
            offset-y
            v-if="this.$store.state.id===null"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="TwitterLogin()"
              >
                <v-avatar
                  size="40"
                  color="grey"
                >
                  <v-icon>mdi-account</v-icon>
                </v-avatar>
              </v-btn>
            </template>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <!-- ヘッダー-->
  </div>
</template>

<script>
import store from '../store';
import UserFace from '@/components/UserFace'

export default {
  components: {
    UserFace,
  },
  data() {
    return {
      Logo_img: process.env.VUE_APP_S3_ENDPOINT + 'icons/サイト名ロゴ-removebg-preview.png',
      Logo_img_sm: process.env.VUE_APP_S3_ENDPOINT + 'icons/サイトロゴ短縮版.png',
      drawer: false,
      dialog: false,
      isMobile: false,
      searchQuery: '',
      loggedIn: false,
      face: this.$store.state.face,
      items: [
        {
          icon: 'mdi-meteor',
          items: [
            {
              title: 'フリー対戦',
              action: '/free_match',
            },
            {
              title: 'ルール',
              action: '/rule',
            },
            ],
          title: '対戦コンテンツ',
          open: true,
        },
        {
          icon: 'mdi-tooltip',
          items: [
            /*
            {
              title: 'ジョブ一覧',
              action: '/jobs',
            },
            */
            {
              title: 'チーム検索',
              action: '/team_search',
            },
          ],
          title: 'その他',
          open: true,
        },
        {
          icon: 'mdi-sticker-text-outline',
          items: [
            {
              title: 'サイトについて',
              action: '/about_app',
            },
            {
              title: '利用規約',
              action: '/terms',
            },
            {
              title: 'Q&A',
              action: '/faq',
            },
            {
              title: 'お問い合わせ',
              action: '/contact',
            },
          ],
          title: 'サイト概要',
          open: true,
        },
      ]
    };
  },

  mounted: function(){
    // console.log("ID:", this.$store.state.id)
    // console.log("名前:", this.$store.state.name)
    // console.log("顔画像:", this.$store.state.face)
    // console.log("ホームワールド:", this.$store.state.world)
    // console.log("チーム:", this.$store.state.team)
    // console.log("ステータス:", this.$store.state.status)
    // console.log("アクセストークン：", this.$store.state.access)
    // console.log("リフレッシュトークン：", this.$store.state.refresh)
    // console.log("規制：", this.$store.state.regulation)
    // console.log("トークン期限：", this.$store.state.isRedirecting)
    if (this.$vuetify.breakpoint.width >= 1380) {
      this.drawer = true;
    }
    // ウィンドウの幅が959px以下の場合、スマホ表示と判断(何故か12pxズレがある)
    this.isMobile = window.innerWidth <= 971;
    window.addEventListener('resize', this.checkMobile);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '75%'
        case 'md': return '50%'
        case 'lg': return '55%'
        case 'xl': return '40%'
      }
      return 0
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    // $routerはpathが同じ場合コンポーネントを再描画しないため、
    // 他人のユーザーページからマイページへ飛ぶとURLは切り替わるが
    // 画面が切り替わらない。そこで$routeオブジェクトをwatchし、ルートの変更を検知している。
    $route() {
      location.reload()
    },
  },

  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 971;
    },

    TwitterLogin: function(){
      window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"
    },

    Logout: function(){
      store.dispatch("logout");
      this.$router.push('/').catch(err => {
        // 既にそのページにいる場合のエラーを無視する
        if (err.name == 'NavigationDuplicated') {
          location.reload()
        }
      });
    },

    ToHome: function(){
      this.$router.push('/')
    },
    ToMyPage: function(){
      this.$router.push('/user/' + this.$store.state.id)
    },
    Search: function(){
      return 'cols=12'
    },

    async onSearchInput(searchQuery) {
      if (!searchQuery) return;
      this.$router.push({
        name: 'Search',
        params: {
          keyword: searchQuery
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  font-family: 'Orbitron', sans-serif;
}
.gradient-background {
  background: linear-gradient(135deg, #f915aa 0%, #06aad3 100%);
}
.custom-icon-color .v-list-group__header .v-list-item__action .v-icon {
  color: #4eee26;
}
.drawer {
  background: linear-gradient(135deg, #f8f52f 0%, #4eee26 130%);
  clip-path: polygon(0 0, 94% 0%, 75% 5%, 85% 10%, 70% 20%, 74% 30%, 87% 35%, 75% 40%, 87% 50%, 70% 60%, 90% 70%, 70% 80%, 80% 85%, 95% 90%, 80% 95%, 100% 100%, 0 100%);
  overflow: hidden;
  position: fixed;
  z-index: 1000;
}
.no-underline {
  text-decoration: none;
}
/* 画面幅が1252px以下の場合 */
@media (max-width: 1251px) {
  .title-link {
    font-size: 24px;
  }
}
/* 画面幅が1253px以上の場合 */
@media (min-width: 1252px) {
  .title-link {
    font-size: 32px;
  }
  .drawer-margin {
    margin-top: 60px;
  }
}
@keyframes glow {
  0% { text-shadow: 0 0 5px #00ff62, 0 0 10px #00ff62, 0 0 15px #00ff62, 0 0 20px #00ff62, 0 0 25px #00ff62; }
  50% { text-shadow: 0 0 10px #00ff62, 0 0 20px #00ff62, 0 0 30px #00ff62, 0 0 40px #00ff62, 0 0 50px #00ff62; }
  100% { text-shadow: 0 0 5px #00ff62, 0 0 10px #00ff62, 0 0 15px #00ff62, 0 0 20px #00ff62, 0 0 25px #00ff62; }
}
.cyberpunk-text {
  font-family: 'Orbitron', sans-serif !important;
  color: #f8f52f;
  letter-spacing: 2px;
  animation: glow 1.5s infinite alternate;
}
@media (max-width: 1050px) {
  .header-image {
    width: 200px;
    height: 100%;
  }
}
@media (min-width: 1051px) {
  .header-image {
    width: 220px;
    height: 100%;
  }
}
@media (max-width: 959px) {
  .header-image-sm {
    width: 150px;
    height: 70%;
  }
}
.custom-width {
  width: 150px;
}
</style>