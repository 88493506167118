<template>
  <v-main>
    <v-row style="height: 100%" align="center" class="mt-6 mb-10">
      <v-col align="center">

        <!-- ログインしていないユーザーがアクセスしてきた時に表示されるダイアログ -->
        <v-dialog
          v-model="dialog_warning"
          :width="dialogWidth"
          persistent
        >
          <v-card
            dark
            elevation="5"
            class="pa-4 text-center mx-auto"
          >
            <v-icon
              class="mb-5"
              color="warning"
              icon="mdi-alert-circle"
              size="112"
            >mdi-alert-circle</v-icon>
            <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
            <p class="mb-4 text-medium-emphasis text-body-2">
              ログインしていません。<br>
              チームの作成にはログインが必要です。<br>
              <span @click="TwitterLogin" class="clickable-text">ログインまたはユーザー登録はこちらから</span>
            </p>
            <v-divider class="mb-4"></v-divider>
            <v-row justify="center">
              <v-col>
                <v-btn
                  class="neon-cancel-btn"
                  @click="ToHome"
                >
                  ホーム画面に戻る
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <!-- ログインしていないユーザーがアクセスしてきた時に表示されるダイアログ -->

        <v-card :width="cardWidth" elevation="5" dark v-if="this.$store.state.refresh">
          <v-row class="justify-center">
            <v-col cols="8" sm="6">
              <v-row justify="center">
                <v-card-title class="title-text">Create <br> New Team</v-card-title>
              </v-row>
              <v-divider color="#5A49B5" style="border-width: 1.5px;"></v-divider>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="8" sm="8">
              <div class="text-center font-weight-bold text-h5">
                チーム名を入力してください
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center align-center" dense no-gutters>
            <v-col cols="10" sm="8">
              <p class="mt-5 font-weight-medium">
                チームは名前を決めるだけで誰でも簡単に作成できます！(20文字以内)
              </p>
              <v-form>
                <v-text-field
                  v-model="team_name"
                  inputmode="numeric"
                  label="入力例: チームクリコン"
                  maxlength="20"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row justify="center" class="pb-3 mt-0">
            <v-col cols="4" sm="3" md="2">
              <v-btn
                class="neon-btn"
                tile
                outlined
                width="100%"
                @click="create_team"
              >
                確認
              </v-btn>

              <!-- チーム作成が成功したときに表示されるダイアログ -->
              <v-dialog
                v-model="dialog"
                :width="dialogWidth"
                persistent
              >
                <v-card
                  dark
                  elevation="5"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="success"
                    icon="mdi-check-circle"
                    size="112"
                  >mdi-check-circle</v-icon>
                  <h2 class="title-text mb-6">
                    Success!
                  </h2>
                  <p class="mb-2 text-medium-emphasis text-body-2">
                    {{ this.$store.state.name }}さんをリーダーとして<br>
                    チーム{{ team_name }}を作成しました！
                  </p>
                  <p class="mb-2 text-medium-emphasis text-body-2">
                    LodestoneやTwitterを活用してチームメンバーを集めましょう！<br>
                  </p>
                  <p class="mb-8 text-medium-emphasis text-body-2">
                    チームページからチーム紹介文やチーム画像など、<br>チームのプロフィールを編集できます。
                  </p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="text-end">
                    <v-btn
                      class="neon-btn"
                      width="90"
                      @click="successAccepting"
                    >
                      閉じる
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <!-- チーム作成が成功したときに表示されるダイアログ -->

              <!-- チーム作成が失敗したときに表示されるダイアログ -->
              <v-dialog
                v-model="dialog_error"
                :width="dialogWidth"
                persistent
              >
                <v-card
                  dark
                  elevation="5"
                  class="pa-4 text-center mx-auto"
                >
                  <v-icon
                    class="mb-5"
                    color="error"
                    icon="mdi-close-circle"
                    size="112"
                  >mdi-close-circle</v-icon>
                  <h2 class="error-text mb-6">チーム作成失敗</h2>
                  <p class="mb-4 text-medium-emphasis text-body-2">
                    チーム作成に失敗しました。<br>
                    お手数ですが、しばらく時間を空けてからもう一度お試しください。
                  </p>
                  <v-divider class="mb-4"></v-divider>
                  <div class="text-end">
                    <v-btn
                      class="neon-cancel-btn"
                      variant="flat"
                      width="90"
                      @click="dialog_error=false"
                    >
                      閉じる
                    </v-btn>
                  </div>
                </v-card>
              </v-dialog>
              <!-- チーム作成が失敗したときに表示されるダイアログ -->
            </v-col>
            <v-col cols="4" sm="3" md="2">
              <v-btn
                class="neon-cancel-btn"
                tile
                outlined
                width="100%"
                @click="ToHome"
              >
                やめる
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from 'axios';
import store from '../store';

export default {
  data() {
    return {
      team_name: '',
      dialog: false,
      dialog_error: false,
      dialog_warning: false,
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '75%'
        case 'md': return '50%'
        case 'lg': return '55%'
        case 'xl': return '40%'
      }
      return 0
    },

    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '50%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },

  },

  mounted: function(){
    document.title = "C.C.Community - チーム作成";
    if (this.$store.state.regulation) {
      this.$router.push('/')
    }
    if (this.$store.state.team) {
      this.$router.push('/team/' + this.$store.state.team.id)
    } else if (!this.$store.state.refresh) {
      this.dialog_warning = true
    }
  },

  methods: {

    // チーム作成
    create_team() {
      const url_team_edit = "/teams/";
      const url_delete_recruiting = "/delete_recruiting/";

      this.$sendRequest('post', url_team_edit, { name: this.team_name })
        .then((response) => {
          store.commit('setTeam', response.data)
          store.commit('setStatus', 'captain')
          this.$sendRequest('delete', url_delete_recruiting)
          this.dialog = true;
        })
    },

    // チーム作成が成功した際に開くダイアログを閉じる
    successAccepting(){
      this.dialog = false
      this.$router.push('/team/' + this.$store.state.team.id)
    },

    ToHome(){
      this.$router.push("/")
    },

    TwitterLogin: function(){
      if (this.$store.state.refresh) {
        this.$router.push('/user/' + this.$store.state.id)
      } else {
        axios
          .get("/twitter_oauth/")
          .then((window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"))
      }
    },

  },
}
</script>

<style scoped>
.clickable-text {
  color: 	#0066c0;
  cursor: pointer;
}
</style>