import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    id: null,
    name: null,
    face: null,
    team: null,
    access: null,
    refresh: null,
    status: null,
    regulation: false,
    isRedirecting: false,
  },

  getters: {},

  mutations: {
    // ID
    setID(state, id){
      state.id = id
    },
    removeID(state){
      state.id = null
    },

    // name
    setName(state, name){
      state.name = name
    },
    removeName(state){
      state.name = null
    },

    // face
    setFace(state, face){
      state.face = face
    },
    removeFace(state){
      state.face = null
    },

    // world
    setWorld(state, world){
      state.world = world
    },
    removeWorld(state){
      state.world = null
    },

    // team
    setTeam(state, team){
      state.team = team
    },
    removeTeam(state){
      state.team = null
    },

    // アクセストークン
    setAccess(state, access){
      state.access = access
    },
    removeAccess(state){
      state.access = null
    },

    // リフレッシュトークン
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    removeRefresh(state){
      state.refresh = null
    },

    // 規制対象ユーザーかどうか
    setRegulation(state, value) {
      state.regulation = value;
    },

    // トークンが有効かどうか
    setIsRedirecting(state, value) {
      state.isRedirecting = value;
    },

    // リーダーステータス
    setStatus(state, status){
      state.status = status
    },
    removeStatus(state){
      state.status = null
    },
  },

  actions: {
    saveID({commit},id){
      commit("saveID",id)
    },
    removeID({commit}){
      commit("removeID")
    },

    saveName({commit},name){
      commit("saveName",name)
    },
    removeName({commit}){
      commit("removeName")
    },

    saveFace({commit},face){
      commit("saveFace",face)
    },
    removeFace({commit}){
      commit("removeFace")
    },

    saveWorld({commit},world){
      commit("saveWorld",world)
    },
    removeWorld({commit}){
      commit("removeWorld")
    },

    saveTeam({commit},team){
      commit("saveTeam",team)
    },
    removeTeam({commit}){
      commit("removeTeam")
    },

    saveAccess({commit},access){
      commit("saveAccess",access)
    },
    removeAccess({commit}){
      commit("removeAccess")
    },

    saveRefresh({commit},refresh){
      commit("saveRefresh",refresh)
    },
    removeRefresh({commit}){
      commit("removeRefresh")
    },

    saveStatus({commit},status){
      commit("saveStatus",status)
    },
    removeStatus({commit}){
      commit("removeStatus")
    },

    // ログアウト処理
    logout({ commit }) {
      commit('removeID');
      commit('removeName');
      commit('removeFace');
      commit('removeWorld');
      commit('removeTeam');
      commit('removeAccess');
      commit('removeRefresh');
      commit('removeStatus');
      commit('setRegulation', false);
    },
  },

  plugins: [createPersistedState(
    {
      key: 'myapp',
      paths: [
        'id',
        'name',
        'face',
        'world',
        'team',
        'access',
        'refresh',
        'status',
        'regulation',
      ],
      storage: window.localStorage,
      // localStrageの設定だと、1つのブラウザで2つのアカウントを操作してテストできない
      // 一時的に保存(ページを閉じると消える)するにはwindow.sessionStorageに変更する
    }
  )]
});
