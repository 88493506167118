import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import '@/assets/global.css';

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT; //バックエンド側のIPとポート
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "http://localhost:8081";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Vue.prototype.$refreshToken = function() {
  return axios.post('/token_refresh/', {
    refresh: this.$store.state.refresh
  }).then((response) => {
    this.$store.commit('setAccess', response.data.access)
  }).catch((error) => {
    if (error.response.status === 401) {
      this.$handleLogout();  // ログアウト処理を行うメソッド
    } else {
      this.$handleError(error);
    }
  });
};

Vue.prototype.$handleError = function() {
  // handleErrorのコード
};

Vue.prototype.$handleLogout = function() {
  this.$store.commit('setIsRedirecting', true);
  this.$store.dispatch("logout");
  alert("トークンの有効期限が切れています。再ログインしてください。")
  window.location = process.env.VUE_APP_API_ENDPOINT + "/twitter_oauth/"
};

Vue.prototype.$sendRequest = async function(method, url, data) {
  try {
    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: 'JWT ' + this.$store.state.access
      }
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        await this.$refreshToken();
        const retryResponse = await axios({
          method: method,
          url: url,
          data: data,
          headers: {
            Authorization: 'JWT ' + this.$store.state.access
          }
        });
        return retryResponse;
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else {
      this.$handleError(error);
      return Promise.reject(error);
    }
  }
};

Vue.prototype.$sendFormRequest = async function(method, url, data) {
  try {
    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'JWT ' + this.$store.state.access
      }
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      try {
        await this.$refreshToken();
        const retryResponse = await axios({
          method: method,
          url: url,
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'JWT ' + this.$store.state.access
          }
        });
        return retryResponse;
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    } else {
      this.$handleError(error);
      return Promise.reject(error);
    }
  }
};


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");