<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Rules
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-card dark class="mt-3">
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            当サービスを利用して対戦を行う際のルールを記載したページです。<br>
            <router-link to="/terms" class="no-underline">利用規約</router-link>も含めて、<span style="color: rgb(255, 30, 30)">必ず全てに目を通し、ルール厳守で対戦を行ってください。</span>
            <br>守れないユーザー様はサービスを利用できなくなる可能性があります。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          フリー対戦とは
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            フリー対戦とは、チーム同士で対戦を行うコンテンツです。
            <br>勝ち負けの集計やレート等はありませんので、FF14内のカジュアルマッチのように気軽に参加することができます。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          フリー対戦のルール
        </div>

        <v-card dark class="mb-8">
          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            参加条件
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            フリー対戦への参加条件は、<span style="color: rgb(255, 30, 30)">チームの人数が5人以上</span>であることのみです。
          </v-card-text>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            対戦のマップと基本設定
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            カスタムマッチの募集をする際は〈<span style="color: rgb(255, 30, 30)">Meteor DC</span>〉で、説明欄に「<span style="color: rgb(255, 30, 30)">CCC フリー対戦</span>」と表記し、パスワードを「<span style="color: rgb(255, 30, 30)">1122</span>」と設定してください。
          </v-card-text>
          <v-card-text class="pt-0 pb-2 custom-line-height">
            対戦マップは基本的には決まっていません。マッチング後に話し合って決めるか、マッチング時の画面に当サイトの方でマップローテーションをランダムで記載していますのでそちらをご利用ください。
            <br>対戦時の設定として、マップギミックはオンにして対戦を行うようにしてください。
          </v-card-text>

          <v-img
            :src="imageUrl"
            aspect-ratio="16/9"
            contain
            class="mb-3 responsive-img"
          ></v-img>

          <v-card
            class="ma-3 mt-0"
            color="rgb(255, 150, 150)"
          >
            <v-card-text class="pt-0 pb-2 custom-line-height">
              <span style="color: rgb(255, 20, 20); line-height: 2.5;" class="font-weight-bold">↓ 特にここを要チェック！</span><br>
              <p style="color: rgb(150, 40, 40)" class="ma-0">
                ・「データセンター」は「Meteor(メテオ)DC」<br>
                ・「コンテンツ」は「クリスタルコンフリクト(パライストラ:カスタムマッチ)」<br>
                ・「自由コメント欄」は「CCC フリー対戦」<br>
                ・「マップギミック」は「オン」<br>
                ・「パスワード」は「1122」(無関係なプレイヤーが入るのを防ぐため)<br>
                ・「観戦者」には両チームの関係者(控えメンバーやコーチ等)のみ入れるようにしてください。無関係なプレイヤーが入った場合は速やかに退席させてください。
              </p>
            </v-card-text>
          </v-card>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            対戦回数とマップ選択
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            対戦回数とマップ選択に明確な決まりはありません。<br>
            当サービスはあくまでもチーム同士のマッチングを目的としていますので、マッチング後の試合数やマップ選択に関しましてはチームリーダーを中心としてチーム同士で話し合って決めてください。<br>
          </v-card-text>
          <v-card-text class="pt-0 pb-2 custom-line-height">
            特に両チームとも希望がない場合は、勝敗をはっきりと決めたいのであれば「2本先取制の最大3試合」、勝敗にこだわらないのであれば「4マップローテーション制」で行うといいかもしれません。<br>
            マップ選択に関しまして、マップ選択に迷った際には当サイトのマッチング後の画面に表示されているマップローテーションをご活用ください。
          </v-card-text>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            ジョブについて
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            <span class="font-weight-bold" style="text-decoration: underline;">ジョブは全ジョブ使用可能で、チーム内でのジョブ重複はなしです。</span><br>
            ジョブ変更に関してもジョブ重複が発生しないのであれば自由にできます。ただし、何らかの理由で対戦が仕切り直しになった場合にジョブを変更するのは禁止です。
          </v-card-text>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            エモートや煽り行為について
          </div>
          <v-divider
          color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            相手を煽るような行為は控えてください。<br>
            どこから煽り行為と受け取られるかは人それぞれですが、一定以上の通報が寄せられた場合は大多数が不快に思う、明らかな煽り行為を行っていると判断します。<br>
          </v-card-text>
          <v-card-text class="pt-0 pb-2 custom-line-height">
            利用者からも運営からも心証は良くない上に、当サービスの利用を規制させていただく場合がございます。<br>
            ただし、煽り行為を理由に対戦を途中でやめることは禁止です。対戦終了後に通報する等対処をお願いします。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          フリー対戦の流れ
        </div>

        <v-card dark class="pb-3">
          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            1. フリー対戦に参加する
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            まず前提として、フリー対戦への参加申請を行えるのはチームのリーダーのみです。<br>
            <router-link to="/free_match" class="no-underline">フリー対戦ページ</router-link>の「フリー対戦に参加する」ボタンをクリックすると、チームが対戦相手を探す状態になります。<br>
            (画面は開発時のものです。実際の画面とは多少異なる場合がございます。)
          </v-card-text>
          <v-img
            :src="imageUrl_1"
            aspect-ratio="16/9"
            contain
            class="mb-3 responsive-img-1"
          ></v-img>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            2. 対戦相手が見つかるまで待機する
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            待機画面に移動しますので、ブラウザの画面を閉じずに待機してください。画面を閉じたり、ブラウザバックしたりすると、参加申請が取り消されない可能性があるので、ページを離れる際は画面のボタンからお願いします。<br>
          </v-card-text>
          <v-card-text class="pt-0 pb-2 custom-line-height">
            対戦相手が見つかると、下記の対戦に移るか辞退するかを選択する画面が表示されます。画面が表示されてから60秒以内に選択されなかった場合、強制的に辞退されますのでご注意ください。<br>
            (画面は開発時のものです。実際の画面とは多少異なる場合がございます。)
          </v-card-text>
          <v-img
            :src="imageUrl_2"
            aspect-ratio="16/9"
            contain
            class="responsive-img-1"
          ></v-img>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            対戦相手が見つかった後にブラウザが強制終了してしまったり、誤って画面を閉じてしまったりした場合は、もう一度「フリー対戦に参加する」ボタンを押すことで下記の画像が表示され、対戦に復帰できます。<br>
            ただし、相手チームが待機画面で待ってくれていた場合に限ります。また、1分30秒以内に対戦に復帰しない場合も、強制的にマッチングがキャンセルとなるので復帰不可能となります。
          </v-card-text>
          <v-img
            :src="imageUrl_3"
            aspect-ratio="16/9"
            contain
            class="mb-3 responsive-img-1"
          ></v-img>

          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            3. 対戦相手とのマッチングが成功したら、ゲームに移る
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            「対戦に移る」を選択すると、対戦ページに移ります。相手チームも「対戦に移る」を選択し、同ページに入ってくるとマッチングが成功しお互いのページに対戦相手が表示されます。<br>
          </v-card-text>
          <v-card-text class="pt-0 pb-2 custom-line-height">
            下記の画面が表示されたら当サイトでの操作は一旦終了です。それぞれ募集側と参加側に分かれていますので、募集側のチームのリーダーはゲーム内でカスタムマッチの募集を立ててください。参加側のチームは、募集側チームのリーダー名を確認し、そのプレイヤーのパーティ募集に参加してください。FF14ゲーム内で実際に対戦を行っていただき、対戦が終了したら右下の「対戦終了」ボタンを押してください。
          </v-card-text>
          <v-img
            :src="imageUrl_4"
            aspect-ratio="16/9"
            contain
            class="responsive-img-1"
          ></v-img>
          <v-card-text class="pt-2 pb-2 custom-line-height">
            数分待っても相手チームが募集に参加してこなかったり、募集が出ていない場合は、画面に表示されている相手チームのリーダーにTellをお願いします。<br>
            Tellが届かない場合や返事がない場合は、対戦終了ボタンを押して次の対戦に移っていただいて構いません。<br>
            何度も同じチームでそういったことが起こる際には、<router-link to="/contact" class="no-underline">お問い合わせページ</router-link>から報告していただけると幸いです。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: process.env.VUE_APP_S3_ENDPOINT + 'icons/in_game_ss.png',
      imageUrl_1: process.env.VUE_APP_S3_ENDPOINT + 'icons/rule_1.png',
      imageUrl_2: process.env.VUE_APP_S3_ENDPOINT + 'icons/rule_2.png',
      imageUrl_3: process.env.VUE_APP_S3_ENDPOINT + 'icons/rule_3.png',
      imageUrl_4: process.env.VUE_APP_S3_ENDPOINT + 'icons/rule_4.png',
    };
  },

  mounted() {
    document.title = "C.C.Community - ルール";
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
; /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
.custom-line-height {
  line-height: 1.8;  /* 行間を1.8に設定 */
}
.no-underline {
  text-decoration: none;
}
/* モバイルデバイスのデフォルトのスタイル */
.responsive-img {
  height: 200px;
}
.responsive-img-1 {
  height: 170px;
}
/* 400px以上 かつ 500px以下の画面サイズのスタイル */
@media (min-width: 400px) and (max-width: 500px) {
  .responsive-img {
    height: 220px;
  }
  .responsive-img-1 {
    height: 180px;
  }
}
/* 501px以上 かつ 750px以下の画面サイズのスタイル */
@media (min-width: 501px) and (max-width: 750px) {
  .responsive-img {
    height: 300px;
  }
  .responsive-img-1 {
    height: 220px;
  }
}
/* タブレットサイズのスタイル (例: 751px以上) */
@media (min-width: 751px) {
  .responsive-img {
    height: 400px;
  }
  .responsive-img-1 {
    height: 300px;
  }
}

/* デスクトップサイズのスタイル (例: 1140px以上) */
@media (min-width: 1140px) {
  .responsive-img {
    height: 500px;
  }
  .responsive-img-1 {
    height: 400px;
  }
}

@media (min-width: 1380px) {
  .responsive-img-1 {
    height: 470px;
  }
}

</style>