<template>
  <v-main>
    <v-dialog
      v-model="dialog"
      :width="cardWidth"
      persistent
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <h2 class="title-text mb-4 mt-2" style="color: #f8f52f;">Waiting</h2>
        <v-progress-linear
          color="#35b3fc"
          indeterminate
        ></v-progress-linear>
        <p class="mb-4 mt-4 text-medium-emphasis text-body-2">
          ログイン処理中です。<br>
          このブラウザタブは開いたままでお待ちください。<br>
        </p>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import store from '../store';
import axios from "axios";

  export default {
    name: "Token",
    data() {
      return {
        twitter_token: location.pathname.replace('/user_reference/',''),
        dialog: true,
        users: [],
        ldst_users: [],
        status: null,
        access: "",
        refresh: "",
      };
    },

    computed: {
      cardWidth () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '95%'
          case 'sm': return '60%'
          case 'md': return '45%'
          case 'lg': return '40%'
          case 'xl': return '30%'
        }
        return 0
      },
    },

    mounted: function() {
      document.title = "C.C.Community - 認証ページ";
      axios
        .get("/check_users/" + this.twitter_token)
        // レスポンスが200番台で返ってきた（登録済みのユーザー）
        .then((response) => {
          this.users = response.data.user
          // 各情報をストアに保存
          store.commit('setID', this.users.id)
          store.commit('setName', this.users.name)
          store.commit('setFace', this.users.face)
          store.commit('setWorld', this.users.world)
          store.commit('setTeam', this.users.team)
          store.commit('setRegulation', this.users.regulation)
          store.commit('setAccess', response.data.access)
          store.commit('setRefresh', response.data.refresh)
          // チームのリーダーであればstoreのstatusにcaptainを保存する
          axios
            .get("/myteam/" + this.users.id)
            .then((response) => {
              const team = response.data
              for (let i = 0; i < team.length; i++) {
                  if (team[i].member_status === 'captain') {
                    this.status = team[i].member_status
                  }
                }
              if (this.status === 'captain') {
                store.commit('setStatus', this.status)
              }
              this.$router.push('/')
            .catch(() => {
              this.$router.push('/')
            })
            })
        })
        // レスポンスが400番台で返ってきた（まだ未登録のユーザー）
        .catch(() => {
          // ユーザー登録画面に遷移させる
          this.$router.push('/user_register')
        })
    },
  }
</script>