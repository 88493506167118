<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Terms
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-card dark class="mt-3">
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            C.C.Communityの利用規約です。当サービスを利用された時点で当規約に同意されたものとみなされますので、必ず最後までお読みください。
            <br>この規約を守っていただけない場合、程度によっては機能規制、アクセス拒否、IPアドレス・ホストの公開、警察サイバー犯罪対策課への通報などを行うことがございます。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          利用規約
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            ・荒らし及び不正行為は全面禁止です。また、明らかに悪意のあると思われる荒らしには絶対に反応(返信や注意など)せずに、無視してください。<br>
            ・極力敬語(丁寧語)を使用してください。程度によって荒らしと判断する場合があります。<br>
            ・宗教やアダルトなどの青少年に悪影響を及ぼす可能性のあるキーワードの表現などは一切禁止します。<br>
            ・すべてのコンテンツにおいて、公式が禁止している外部ツールを利用して対戦に参加する行為は全面禁止です。<br>
            ・当サイトを著しくサーバの負荷をかける行為、もしくはサーバをダウンさせる行為は、アクセス制限及び法的な対応を行う場合があります。<br>
            ・利用規約は当サイトの都合により改正することができます。追加、削除、別途定めた場合は改正規約に同意した前提とします。
          </v-card-text>
        </v-card>

        <div
          class="sub-title-text mt-8 mb-3 pa-2 custom-shape-background"
        >
          免責事項
        </div>

        <v-card dark>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            当サービスではご利用上の安全性などに細心の注意を払っておりますが、生じた如何なるトラブル・損失・損害に対しても責任を負いません。予めご了承ください。
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  mounted() {
    document.title = "C.C.Community - 利用規約";
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f; /* 任意の背景色 */
; /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}
.custom-line-height {
  line-height: 1.8;  /* 行間を1.8に設定 */
}
.no-underline {
  text-decoration: none;
}

</style>