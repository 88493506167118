<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Team Search
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-card dark class="mt-3">
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            チーム検索ページです。チーム紹介文の中のキーワードからチーム検索を行うことができます。<br>
            メンバーを募集しているチームはどんなジョブを募集しているのか、初心者歓迎、上級者募集等をチームの紹介文に記載し、メンバー募集中の設定を行うことで、チームに入りたいプレイヤーから見つけてもらいやすくなります。<br>
            ※チーム名から検索を行いたい場合は、ヘッダーかサイドメニューの検索欄から検索をお願いします。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" sm="6">
        <v-text-field
          class="neon-glow"
          v-model="searchQuery"
          label="チーム紹介文を検索"
          solo
          append-icon="mdi-magnify"
          hide-details
          dense
          clearable
          dark
          @keyup.enter="searchTeams"
          @click:append="searchTeams"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      v-if="searchQuery"
      class="mb-5"
    >
      <v-col cols="11" sm="6">
        <div
          class="neon-text neon-border mb-3"
        >
          {{ teams.length }}チームの募集が見つかりました。
        </div>
        <v-card dark>
          <v-list class="pb-0">
            <v-list-item-group>
              <div v-for="team in paginatedMatches" :key="team.id" class="ma-2 mt-1">
                <v-row class="mb-2 mb-md-2">
                  <v-col class="pb-0">
                    <router-link :to="`/team/${team.id}`" class="no-underline">
                      <v-list-item-title class="team-name">{{ team.name }}</v-list-item-title>
                    </router-link>
                    <v-card :style="{ minHeight: '80px', maxHeight: '150px', overflow: 'auto'}" class="introduction-card">
                      <pre style="white-space: pre-wrap;">{{ team.introduction }}</pre>
                    </v-card>
                    <router-link :to="`/user/${team.leader.member_id}`" class="no-underline">
                      <v-list-item-title class="leader-name">Leader : {{ team.leader.member_name }}</v-list-item-title>
                    </router-link>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </v-list-item-group>
          </v-list>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            class="pb-2 pt-2 neon-pagination"
            color="#1e1e1e"
          ></v-pagination>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      v-if="!searchQuery"
      class="mb-5"
    >
      <v-col cols="11" sm="6">
        <RecruitingTeamList />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from 'axios';
import RecruitingTeamList from '../components/RecruitingTeamList.vue';

export default {
  components: {
    RecruitingTeamList
  },

  data() {
    return {
      teams: [],
      searchQuery: '',
      currentPage: 1,
      perPage: 10,
    };
  },

  computed: {
    paginatedMatches() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.teams.slice(start, end);
    },

    totalPages() {
      return Math.ceil(this.teams.length / this.perPage);
    },
  },

  methods: {
    async searchTeams() {
      try {
        const response = await axios.get(`/search_recruiting_teams/?search_word=${this.searchQuery}`);
        this.teams = response.data;
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
      }
    }
  },

  mounted() {
    document.title = "C.C.Community - チーム検索";
    window.scrollTo(0, 0);
  },

}
</script>

<style scoped>
.highlight {
  background-color: yellow;
}
.no-underline {
  text-decoration: none;
}
.team-name {
  color: #f8f52f;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 120%;
}
.leader-name {
  color: #664d99;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 100%;
}
.neon-text {
  font-family: 'Orbitron', sans-serif !important;
  color: #f915aa;
  text-shadow:
    0 0 3px #fc3613,
    0 0 6px #fc3613,
    0 0 9px #fc3613,
    0 0 12px #fc3613,
    0 0 15px #fc3613;
}
.neon-border {
  display: inline-block;
  border: 2px solid #06aad3;
  padding: 5px;
  box-shadow:
    0 0 2px #06aad3,
    0 0 4px #06aad3,
    0 0 6px #06aad3,
    0 0 8px #00dfc1,
    0 0 10px #00dfc1;
  background-color: black;
}
.neon-pagination .v-pagination__item {
  color: #06aad3;
  text-shadow: 0 0 5px #06aad3, 0 0 10px #06aad3, 0 0 20px #06aad3, 0 0 30px #06aad3, 0 0 40px #06aad3;
}
.neon-pagination .v-pagination__item--active {
  color: #ffffff !important;
  text-shadow: 0 0 5px #00ff62, 0 0 10px #00ff62, 0 0 20px #00ff62, 0 0 30px #00ff62, 0 0 40px #00ff62;
}
.introduction-card {
  border: 3px solid #78ffac;
  box-shadow:
    0 0 2px #00ff62,
    0 0 4px #00ff62,
    0 0 6px #00ff62 !important;
  background-color: #1d1d1d;
  color: rgb(190, 190, 190);
  font-size: 90%;
}

/* スクロールバー全体 */
::-webkit-scrollbar {
  width: 5px;
}

/* トラック（背景） */
::-webkit-scrollbar-track {
  background: #000;  /* ダーク背景 */
}

/* ハンドル（バー） */
::-webkit-scrollbar-thumb {
  background: linear-gradient(100deg, #00ff62, #f8f52f); /* 緑と黄色のグラデーション */
  border-radius: 10px; /* 角を丸く */
  box-shadow: 0 0 10px #fff,  /* 内側の白い光 */
              0 0 5px #00ff62,  /* 緑の光 */
              0 0 15px #00ff62,
              0 0 20px #00ff62,
              0 0 25px #00ff62,
              0 0 30px #00ff62,
              0 0 35px #00ff62;
}

/* ハンドル（バー）: Hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #00ff62, #f8f52f); /* グラデーション変更 */
}
</style>