<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8"
    >
      <v-col cols="11" md="8" xl="7">
        <div
          class="title-text"
        >
          Contact Us
        </div>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

        <v-card dark class="mt-3">
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            C.C.Communityに関するお問い合わせは下記の入力フォームからお願いします。(入力フォームはログインしていないと表示されません。)<br>
            送信前に、<router-link to="/faq" class="no-underline">Q&Aページ</router-link>にお問い合わせ内容があるか確認をお願いします。<br>
            返信は行えないので、質問形式の問い合わせはお控えください。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="this.$store.state.refresh" class="pa-0 mb-5">
      <v-col cols="11" md="8" xl="7">
        <v-card dark>
          <div
            class="text-subtitle-1 pa-3 pb-0"
            style="color: #f8f52f;"
          >
            お問い合わせフォーム
          </div>
          <v-divider
            color="#5A49B5"
            style="border-width: 1.5px;"
            class="ma-3 mt-0 mb-0"
          ></v-divider>
          <v-card-text class="pa-3 pt-2 pb-2 custom-line-height">
            <v-form ref="form" @submit.prevent="submitInquiry">
              <v-select
                v-model="category"
                :items="categories"
                label="カテゴリー"
                required
                :menu-props="{ offsetY: true, dark:true }"
              ></v-select>
              <v-textarea
                v-model="content"
                placeholder="お問い合わせ内容を入力"
                :rules="contentRules"
                required
                color="#7A7585"
                clearable
                outlined
                auto-grow
                counter
                rows="5"
              ></v-textarea>
              <v-row>
                <v-col class="text-right">
                  <v-btn :disabled="!category" @click="handleSubmission" outlined class="neon-btn mb-2">
                    送信
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- チームリーダーの場合 -->
    <v-dialog
      v-model="dialog_cap"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          icon="mdi-alert-circle"
          size="112"
        >mdi-alert-circle</v-icon>
        <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          あなたはチームのリーダーです。<br>
          したがって、退会すると同時にチームも解散となります。<br>
          チームを引き継ぐ人がいる場合は、先にリーダー権限を委譲することをおすすめします。
        </p>
        <p class="mb-4 text-medium-emphasis text-body-2">
          また、この操作を実行すると取り消すことはできません。<br>
          本当に退会しますか？
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="neon-btn"
              @click="deleteUser"
            >
              退会する
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="dialog_cap=false"
            >
              キャンセル
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- チームリーダーの場合 -->

    <!-- チームメンバーの場合 -->
    <v-dialog
      v-model="dialog_mem"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          icon="mdi-alert-circle"
          size="112"
        >mdi-alert-circle</v-icon>
        <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          あなたはチームに所属しています。<br>
          したがって、退会すると同時にチームからも脱退となります。
        </p>
        <p class="mb-4 text-medium-emphasis text-body-2">
          また、この操作を実行すると取り消すことはできません。<br>
          本当に退会しますか？
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="neon-btn"
              @click="deleteUser"
            >
              退会する
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="dialog_mem=false"
            >
              キャンセル
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- チームメンバーの場合 -->

    <!-- チーム未所属のユーザーの場合 -->
    <v-dialog
      v-model="dialog"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="warning"
          icon="mdi-alert-circle"
          size="112"
        >mdi-alert-circle</v-icon>
        <h2 class="warning-text mb-6" style="color: #f8f52f;">Warning!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          この操作を実行すると取り消すことはできません。<br>
          本当に退会しますか？
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="neon-btn"
              @click="deleteUser"
            >
              退会する
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="dialog=false"
            >
              キャンセル
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- チーム未所属のユーザーの場合 -->

    <!-- 規制ユーザーに対するエラーメッセージ -->
    <v-dialog
      v-model="dialog_error"
      :width="cardWidth"
    >
      <v-card
        dark
        elevation="5"
        class="pa-4 text-center mx-auto"
      >
        <v-icon
          class="mb-5"
          color="error"
          size="112"
        >mdi-close-circle</v-icon>
        <h2 class="warning-text mb-6" style="color: #f8f52f;">Error!</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">
          機能制限がかかっています。<br>
          規制中はユーザー削除できません。
        </p>
        <v-divider class="mb-4"></v-divider>
        <v-row justify="center">
          <v-col>
            <v-btn
              class="neon-cancel-btn"
              @click="dialog_error=false"
            >
              閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- 規制ユーザーに対するエラーメッセージ -->

  </v-main>
</template>

<script>
import axios from "axios";
import store from '../store';

export default {
  data() {
    return {
      category: '',
      content: '',
      dialog_cap: false,
      dialog_mem: false,
      dialog: false,
      dialog_error: false,
      categories: [
        { text: '不具合報告', value: 'bug_report' },
        { text: '荒らし・煽り行為の報告', value: 'trolling_report' },
        { text: 'サイトへの要望', value: 'feature_request' },
        { text: 'その他', value: 'others' },
        { text: 'ユーザー削除', value: 'user_deletion' }
      ],
    };
  },

  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '45%'
        case 'lg': return '35%'
        case 'xl': return '28%'
      }
      return 0
    },

    contentRules() {
      if (this.category !== 'user_deletion') {
        return [v => !!v || '内容は必須です'];
      }
      return [];
    },
  },

  methods: {

    // ユーザーによって警告内容を変える
    openDialog() {
      if (this.$store.state.team && this.$store.state.status === 'captain') {
        this.dialog_cap = true
      } else if (this.$store.state.team && this.$store.state.status === null) {
        this.dialog_mem = true
      } else {
        this.dialog = true
      }
    },

    // 送信
    handleSubmission() {
      // ユーザー削除が選択されている場合
      if (this.category === 'user_deletion') {
        axios
          // 規制の有無を確認
          .get("/myteam/" + this.$store.state.id)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              // TeamUserからユーザー情報を取得し、規制されているユーザーであればStatusとTeamをnullにする(規制時のための処理)
              if (response.data[0].member.regulation) {
                this.$store.commit('setRegulation', true)
                this.$store.commit('removeStatus')
                this.$store.commit('removeTeam')
              }
              // リーダーかどうか確認して、リーダーだったら保存(規制時のための処理)
              if (response.data[0].member_status === 'captain') {
                this.$store.commit('setStatus', response.data[0].member_status)
              }
              // 規制対象でなければ削除可能
              if (!this.$store.state.regulation) {
                // ユーザー削除の処理
                this.openDialog();
              } else {
                this.dialog_error = true
              }
            } else {
              this.openDialog();
            }
          })
      // お問い合わせが選択されている場合の処理
      } else {
        this.submitInquiry();
      }
    },

    // ユーザー削除
    async deleteUser() {
      try {
        if (this.$store.state.team && this.$store.state.status === 'captain') {
          // チームリーダーの場合、まずチームを削除
          await this.$sendRequest('DELETE', '/teams/');
        } else if (this.$store.state.team && this.$store.state.status === null) {
          // チームリーダーでない場合、まずチームから脱退
          const teamId = this.$store.state.team.id;
          await this.$sendRequest('DELETE', `/join_or_leave_team/${teamId}`);
        }

        // ユーザー自体を削除
        await this.$sendRequest('DELETE', '/users_delete/', {});

        store.commit('removeID')
        store.commit('removeName')
        store.commit('removeFace')
        store.commit('removeWorld')
        store.commit('removeTeam')
        store.commit('removeAccess')
        store.commit('removeRefresh')
        store.commit('removeStatus')
        this.$router.push('/')

        alert('ユーザーが正常に削除されました。');

      } catch (error) {
        this.handleError(error);
      }
    },

    // お問い合わせ送信
    async submitInquiry() {
      if (this.$refs.form.validate()) {
        try {
          const response = await this.$sendRequest('post', '/inquiries/', {
            category: this.category,
            content: this.content
          });
          if (response.status === 201) {
            // 成功した場合の処理
            this.$refs.form.reset();
            alert('お問い合わせが送信されました');
          } else {
            // 失敗した場合の処理（通常はこの部分には到達しない）
            alert('何か問題が発生しました');
          }
        } catch (error) {
          // API呼び出しでエラーが発生した場合の処理
          this.handleError(error);
        }
      }
    },
  },

  created() {
    document.title = "C.C.Community - お問い合わせ";
    window.scrollTo(0, 0);
    if (this.$store.state.refresh) {
      axios
        // チーム情報を取得（招待されているチームが存在するかどうか確認）
        .get("/myteam/" + this.$store.state.id)
        .then((response) => {
          // TeamUserからユーザー情報を取得し、規制されているユーザーであればStatusとTeamをnullにする(規制時のための処理)
          if (response.data[0].member.regulation) {
            this.$store.commit('setRegulation', true)
            this.$store.commit('removeStatus')
            this.$store.commit('removeTeam')
          }
          // リーダーかどうか確認して、リーダーだったら保存(規制時のための処理)
          if (response.data[0].member_status === 'captain') {
            this.$store.commit('setStatus', response.data[0].member_status)
          }
        })
    }
  }
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
</style>