<template>
  <v-main>
    <v-row style="height: 100%;" justify="center" class="mt-8 mb-8">
      <v-col cols="11" md="8" xl="7">
        <v-row>
          <v-col cols="10">
            <div class="title-text" v-if="this.$store.state.id === null">
              {{ this.name }}
            </div>
            <div class="title-text" v-else-if="this.$store.state.refresh && this.ldst_numbers == this.$store.state.id">
              My Page
            </div>
            <div class="title-text" v-else>
              {{ this.name }}
            </div>
          </v-col>
          <v-col cols="2" class="d-flex flex-column justify-end">
            <v-btn
              outlined
              v-if="this.$store.state.refresh && this.ldst_numbers == this.$store.state.id"
              @click="dialog = true; GetJobList();"
              class="align-self-end neon-btn"
            >
              編集
            </v-btn>
            <v-dialog v-model="dialog" activator="parent" :width="cardWidth" persistent>
              <!-- ダイアログにこれ入れてもいい（ダイアログ外をクリックしてダイアログを消す）
              @click:outside="ClearProfile()" -->
              <v-card elevation="5" dark>
                <v-row style="height: 100%;" justify="center" no-gutters>
                  <v-col>
                    <div class="title-text pt-2 pb-2 pl-3">
                      Plofile
                    </div>
                    <v-divider color="#5A49B5" no-gutters class="mb-5 ml-3 mr-3" style="border-width: 1.5px;"></v-divider>
                    <v-row class="mt-3" justify="center" no-gutters>
                      <v-col>
                        <!-- 自己紹介フォーム -->
                        <v-row justify="center" no-gutters>
                          <v-col cols="10">
                            <div class="text-h6 text-xl-body-1 font-weight-bold pb-2">
                              自己紹介
                            </div>
                          </v-col>
                          <v-col cols="10">
                            <v-textarea
                              v-model="introductions"
                              color="#7A7585"
                              clearable
                              outlined
                              auto-grow
                              counter
                              placeholder="テキストを入力"
                              rows="5"
                              maxlength="200"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <!-- 自己紹介フォーム -->
                        <!-- ジョブ選択フォーム -->
                        <v-row justify="center" no-gutters>
                          <v-col cols="10">
                            <div class="text-h6 text-xl-body-1 font-weight-bold">
                              よく使うジョブ(2ジョブまで選択可能)
                            </div>
                          </v-col>
                          <v-col cols="10">
                            <v-select v-model="selectedJobs" :items="jobs" item-value="id" item-text="name" return-object
                              multiple clearable max="2" class="pt-0" :menu-props="{ offsetY: true, dark: true }">
                              <template v-slot:selection="{ item }">
                                <v-list-item>
                                  <v-img :src="item.image" max-width="35px"></v-img>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item @click="toggleItemSelection(item)">
                                  <v-img :src="item.image" max-width="35px"></v-img>
                                  <v-list-item-content class="ml-2">
                                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="10">
                            <p class="text-subtitle-2" style="color: gray;" v-if="this.user_jobs.length >= 1">
                              ※選択肢の最後にある「未設定」を選択に含めると、よく使うジョブを未設定の状態に初期化できます
                            </p>
                          </v-col>
                        </v-row>
                        <!-- ジョブ選択フォーム -->
                        <v-row class="mt-3" justify="center" no-gutters>
                          <v-col>
                            <!-- キャンセルand保存ボタン -->
                            <v-row no-gutters justify="center" class="mt-3 mb-5">
                              <v-spacer></v-spacer>
                              <v-col cols="3" sm="2" md="2">
                                <v-btn
                                  tile
                                  outlined
                                  width="100%"
                                  @click="SendProfile()"
                                  :disabled="isSendDisabled"
                                  class="neon-btn"
                                >
                                  保存
                                </v-btn>
                              </v-col>
                              <v-spacer class="d-sm-block"></v-spacer>
                              <v-spacer class="d-none d-sm-block"></v-spacer>
                              <v-spacer class="d-none d-sm-block"></v-spacer>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-spacer></v-spacer>
                              <v-col cols="3" sm="2" md="2">
                                <v-btn
                                  tile
                                  outlined
                                  width="100%"
                                  @click="ClearProfile()"
                                  class="neon-cancel-btn"
                                >
                                  キャンセル
                                </v-btn>
                              </v-col>
                              <v-spacer></v-spacer>
                            </v-row>
                            <!-- キャンセルor保存ボタン -->
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>
        <v-row class="mt-5">
          <v-col cols="12" sm="6" md="5">
            <div class="sub-title-text mb-3 pa-2 custom-shape-background" style="display: flex; align-items: center;">
              ユーザー情報
              <!-- ロドスト情報更新用ボタン -->
              <v-btn class="pa-0 ml-2" elevation="0" icon height="30" width="30"
                v-if="this.$store.state.refresh && this.ldst_numbers == this.$store.state.id" @click="dialog_1 = true">
                <v-icon style="color: #3498db;">mdi-sync</v-icon>
              </v-btn>
              <!-- ロドスト情報更新用ボタン -->
              <!-- 更新用ダイアログ -->
              <v-dialog v-model="dialog_1" activator="parent" :width="cardWidth" persistent>
                <v-card elevation="5" dark>
                  <v-row style="height: 100%;" justify="center" no-gutters>
                    <v-col>
                      <div class="title-text pt-3 pb-2 pl-3">
                        Update User Information
                      </div>
                      <v-divider color="#5A49B5" no-gutters></v-divider>
                      <v-row justify="center" no-gutters class="mt-5 mb-8">
                        <v-col cols="10">
                          <div>ゲーム内キャラクターの名前や、ホームワールドを変更した際に「Lodestone」の情報が更新された後に更新してください。</div>
                          <div>（プロフィール画像は自動で更新されます。）</div>
                        </v-col>
                      </v-row>
                      <!-- キャンセルand確認ボタン -->
                      <v-row no-gutters justify="center" class="mt-5 mb-5">
                        <v-spacer></v-spacer>
                        <v-col cols="3" sm="2" md="2">
                          <v-btn
                            class="neon-btn"
                            tile
                            outlined
                            width="100%"
                            @click="CheckLdstUser()"
                          >
                            確認
                          </v-btn>

                          <!-- キャラクター確認用ダイアログ -->
                          <v-dialog v-model="dialog_2" activator="parent" :width="cardWidth" persistent>
                            <v-card elevation="5" dark>
                              <v-row style="height: 100%;" justify="center" no-gutters>
                                <v-col>
                                  <div class="title-text pt-3 pb-2 pl-3">
                                    Update User Information
                                  </div>
                                  <v-divider color="#5A49B5" no-gutters></v-divider>
                                  <v-row justify="center" no-gutters class="mt-5 mb-3">
                                    <v-col cols="10">
                                      <div class="text-center font-weight-bold text-h5">
                                        下記の情報に更新しますか？
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <!-- 新たなユーザー情報を表示するカード -->
                                  <v-row class="justify-center" no-gutters>
                                    <v-col cols="10" sm="8">
                                      <v-card class="mx-auto" max-width="344" outlined>
                                        <v-list-item>
                                          <v-list-item-avatar tile size="80">
                                            <v-img :src="this.ldst_users.face"></v-img>
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                            <v-list-item-title class="text-h5 ma-2"
                                              :style="{ 'word-wrap': 'break-word' }">
                                              {{ this.ldst_users.name }}
                                            </v-list-item-title>
                                            <v-list-item-title class="ma-2">
                                              {{ this.ldst_users.world }}
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                  <!-- 新たなユーザー情報を表示するカード -->
                                  <!-- キャンセルand更新ボタン -->
                                  <v-row no-gutters justify="center" class="mt-8 mb-5">
                                    <v-spacer></v-spacer>
                                    <v-col cols="3" sm="2" md="2">
                                      <v-btn
                                        class="neon-btn"
                                        tile
                                        outlined
                                        width="100%"
                                        @click="ReloadLdstUser()"
                                        :disabled="disableDeclineButton"
                                      >
                                        更新
                                      </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="3" sm="2" md="2">
                                      <v-btn class="neon-cancel-btn" tile outlined width="100%"
                                        @click="dialog_1 = false, dialog_2 = false">
                                        キャンセル
                                      </v-btn>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                  </v-row>
                                  <!-- キャンセルor更新ボタン -->
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-dialog>
                          <!-- キャラクター確認用ダイアログ -->

                        </v-col>
                        <v-spacer class="d-sm-block"></v-spacer>
                        <v-spacer class="d-none d-sm-block"></v-spacer>
                        <v-spacer class="d-none d-sm-block"></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-col cols="3" sm="2" md="2">
                          <v-btn class="neon-cancel-btn" tile outlined width="100%" @click="dialog_1 = false">
                            キャンセル
                          </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <!-- キャンセルor確認ボタン -->
                    </v-col>
                  </v-row>
                </v-card>
              </v-dialog>
              <!-- 更新用ダイアログ -->
            </div>
            <v-card class="mx-auto mb-3" outlined dark>
              <v-list-item>
                <v-list-item-avatar tile size="80">
                  <v-img :src="this.face"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-row class="mb-0 pb-0">
                    <v-list-item-title class="text-h6 ml-5 mt-3" justify="start">
                      {{ this.name }}
                    </v-list-item-title>
                    <v-list-item-title class="ml-5 mb-3">
                      {{ this.world }}
                    </v-list-item-title>
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="mt-0 pt-0">
                    <v-col class="d-flex mt-0">
                      <v-list-item-icon v-if="this.job_image_1" class="ml-2 mr-0 mt-1 mb-0">
                        <v-img width="40" :src="this.job_image_1"></v-img>
                      </v-list-item-icon>
                      <v-list-item-icon v-if="this.job_image_2" class="ml-1 mt-1 mb-0">
                        <v-img width="40" :src="this.job_image_2"></v-img>
                      </v-list-item-icon>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card class="mx-auto" outlined dark>
              <v-list class="pa-0">
                <v-list-item @click="ToLodestone()">
                  <v-list-item-title class="text-h6 font-weight-regular pt-2 pb-2">Lodestone</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="ToTwitter()">
                  <v-list-item-title class="text-h6 font-weight-regular pt-2 pb-2">Twitter</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="7">
            <div class="sub-title-text mb-3 pa-2 custom-shape-background">
              自己紹介
            </div>
            <v-card
              class="mx-auto mb-3 neon-card"
              outlined
              dark
              :style="{ minHeight: '100px', maxHeight: '200px', overflow: 'auto' }"
            >
              <pre style="white-space: pre-wrap;">{{ this.users.introduction }}</pre>
            </v-card>
            <div class="sub-title-text mb-3 pa-2 custom-shape-background" v-if="this.users.team">
              {{ this.users.team.name }}
            </div>
            <div v-else>
              <div class="sub-title-text pa-2 custom-shape-background">
                チーム未所属
              </div>

              <v-card
                class="mx-auto mt-3"
                outlined
                dark
                v-if="this.$store.state.refresh && this.ldst_numbers == this.$store.state.id && !this.$store.state.regulation"
              >
                <div class="pa-3 font-weight-black" style="color: #FF1493;" v-if="this.team_admission.length === 0">
                  チーム招待は届いていません。
                </div>
                <div class="pa-3 font-weight-black" style="color: #FF1493;" v-if="this.team_admission.length > 0">
                  {{ this.team_admission.length }}件のチーム招待が届いています！
                </div>
                <RecruitedTeamList :users="users" :team_admission="team_admission"></RecruitedTeamList>
              </v-card>

              <v-card
                dark
                class="mt-3"
                v-if="this.$store.state.refresh && this.ldst_numbers == this.$store.state.id && !this.$store.state.regulation"
              >
                <div class="pa-3 pb-0" style="color: #3498db;">
                  <u>チームは名前を決めるだけで簡単に作成できます。</u>
                  <br>
                  <u>気軽に作成してみましょう！</u>
                </div>
                <v-btn outlined class="neon-btn ma-3" @click="ToTeamCreate">
                  チーム作成
                  <v-icon style="color: #00ff62;" large>mdi-arrow-right-bold-box-outline</v-icon>
                </v-btn>
              </v-card>

              <div v-if="this.$store.state.status === 'captain' && this.$store.state.team.recruiting_members === true">
                <RecruitingDialog :users="users" :admission="admission"></RecruitingDialog>
              </div>
            </div>

            <div v-if="this.users.team" class="parent">
              <v-img :src="this.team_image" class="mb-2"></v-img>
              <v-btn outlined @click="ToTeamPage" class="mb-16 neon-btn">
                チームページへ
                <v-icon style="color: #00ff62;" large>mdi-arrow-right-bold-box-outline</v-icon>
              </v-btn>
            </div>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import store from '../store';
import axios from "axios";
import RecruitingDialog from "../components/RecruitingDialog.vue"
import RecruitedTeamList from "../components/RecruitedTeamList.vue"

export default {
  components: {
    RecruitingDialog,
    RecruitedTeamList,
  },
  data() {
    return {
      dialog: false,
      dialog_1: false,
      dialog_2: false,
      users: [],
      ldst_users: [],
      name: "",
      face: "",
      world: "",
      team_image: "",
      tentative_team: "",
      team_admission: [],
      admission: false,
      ldst_numbers: location.pathname.replace('/user/', ''),
      introductions: "",
      jobs: [],
      selectedJobs: [],
      user_jobs: [],
      job_image_1: "",
      job_image_2: "",
      disableDeclineButton: true,
    };
  },

  mounted: function () {
    document.title = "C.C.Community - ユーザーページ";
    window.scrollTo(0, 0);
    axios
      // URLパラメータ（ロドストID）からユーザー情報を取得
      .get("/users/" + this.ldst_numbers)
      .then((response) => {
        this.users = response.data
        this.name = this.users.name
        this.face = this.users.face
        this.world = this.users.world
        if (this.users.team)
          this.team_image = process.env.VUE_APP_S3_TEAM_IMAGE_ENDPOINT + this.users.team.image
        axios
          // URLパラメータ（ロドストID）からユーザーのジョブ情報を取得
          .get("/job_users/" + this.users.id)
          .then((response) => {
            this.user_jobs = response.data
            if (this.user_jobs.length === 2) {
              this.job_image_1 = this.user_jobs[0].image
              this.job_image_2 = this.user_jobs[1].image
            } else if (this.user_jobs.length === 1) {
              this.job_image_1 = this.user_jobs[0].image
            } else if (this.user_jobs.length === 0) {
              return
            }
          })
        axios
          // チーム情報を取得（招待されているチームが存在するかどうか確認）
          .get("/myteam/" + this.users.id)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              // マイページにアクセスしている場合
              if (this.ldst_numbers == this.$store.state.id) {
                // TeamUserからユーザー情報を取得し、規制されているユーザーであればStatusとTeamをnullにする(規制時のための処理)
                if (response.data[0].member.regulation) {
                  this.$store.commit('setRegulation', true)
                  this.$store.commit('removeStatus')
                  this.$store.commit('removeTeam')
                }
                // リーダーかどうか確認して、リーダーだったら保存(規制時のための処理)
                if (response.data[0].member_status === 'captain') {
                  this.$store.commit('setStatus', response.data[0].member_status)
                }
              }
              this.tentative_team = response.data
              for (let i = 0; i < this.tentative_team.length; i++) {
                if (this.tentative_team[i].admission_status == "tentative") {
                  this.team_admission.push(this.tentative_team[i].team);
                }
              }
              // チームに誘われてる＝チーム未加入なのでストアを更新
              if (this.team_admission.lengh > 0) {
                this.$store.commit('removeStatus')
                this.$store.commit('removeTeam')
              }
              // どこかのチームのリーダーが他人のユーザーページを訪れた際に招待ボタンが出るかどうかの分岐で使う
              for (let i = 0; i < this.team_admission.length; i++) {
                // チームリーダーのストアからチーム情報を取得し、訪れているユーザーページのユーザーを自分のチームに招待していないかどうか確認する
                if (this.$store.state.refresh && this.$store.state.team.id === this.team_admission[i].id) {
                  this.admission = true;
                  break; // 一致するチームが見つかったら、このループを抜ける
                }
              }
            // myteamからレスポンスがない＝チーム未加入なのでストアを更新
            } else {
              if (this.ldst_numbers == this.$store.state.id) {
                this.$store.commit('removeStatus')
                this.$store.commit('removeTeam')
              }
            }
          })
      })
      .catch(() => {
        this.$router.push('/')
      })
  },

  watch: {
    introductions(newVal) {
      const newLines = (newVal.match(/\n/g) || []).length;
      const effectiveLength = newVal.length + newLines;  // 1文字の改行が2文字としてカウントされるため調整

      if (effectiveLength > 200) {
        this.introductions = newVal.substring(0, 200 - newLines);
      }
    }
  },

  computed: {
    cardWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '60%'
        case 'md': return '50%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },
    confirmWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '60%'
        case 'md': return '50%'
        case 'lg': return '40%'
        case 'xl': return '30%'
      }
      return 0
    },
    isSendDisabled() {
      return !(this.introductions || this.selectedJobs && this.selectedJobs.length > 0);
    },
  },

  methods: {

    GetJobList: function () {
      this.introductions = this.users.introduction
      axios
        .get("/jobs/")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.jobs.push(response.data[i])
          }
          if (this.user_jobs.length >= 1) {
            this.jobs.push({ id: 20, image: "https://img.game8.jp/3620652/9e56730bb1442f5ef69cbef6e1dbcee4.jpeg/thumb", name: "未設定" })
          }
        })
    },

    toggleItemSelection: function (item) {
      const index = this.selectedJobs.findIndex(selectedItem => selectedItem.id === item.id);
      if (index !== -1) {
        this.selectedJobs.splice(index, 1);
      } else if (this.selectedJobs.length < 2) {
        this.selectedJobs.push(item);
      }
    },

    ToLodestone: function () {
      window.open('https://jp.finalfantasyxiv.com/lodestone/character/' + this.ldst_numbers);
    },

    ToTwitter: function () {
      window.open('https://twitter.com/' + this.users.twitter_user)
    },

    ToTeamPage: function () {
      this.$router.push('/team/' + this.users.team.id)
    },

    ToTeamCreate: function () {
      this.$router.push('/team_create')
    },

    async SendProfile() {
      if (this.introductions) {
        await this.$sendRequest("put", "/users_edit/", {
          introduction: this.introductions
        });
      }

      if (this.selectedJobs && this.selectedJobs.length > 0) {
        let url = "/job_change/" + this.users.id;
        let data = {
          main_job: this.selectedJobs[0].id,
          sub_job: this.selectedJobs.length > 1 ? this.selectedJobs[1].id : null,
          user: this.users.id
        };

        if (this.user_jobs.length !== 0) {
          if (this.selectedJobs[0].id === 20) {
            await this.$sendRequest("delete", "/job_users/" + this.users.id);
          } else {
            await this.$sendRequest("put", url, data);
          }
        } else if (this.user_jobs.length === 0) {
          await this.$sendRequest("post", url, data);
        }
      }

      location.reload();
    },

    ClearProfile: function () {
      this.introductions = "";
      this.selectedJobs = [];
      this.dialog = false;
    },

    CheckLdstUser: function () {
      axios
        .get("/check_ldst_users/" + this.ldst_numbers)
        .then((response) => {
          this.ldst_users = response.data
          this.dialog_2 = true
          this.disableDeclineButton = false
        })
    },

    ReloadLdstUser: function () {
      this.$sendRequest("put", "/users_edit/", {
        ldst_name: this.ldst_users.name,
        ldst_world: this.ldst_users.world
      })
        .then((response) => {
          this.users = response.data
          store.commit('setUser', this.users)
          this.dialog_1 = false;
          this.dialog_2 = false;
          location.reload()
        })
    },
  }
}
</script>

<style scoped>
.custom-shape-background {
  background: #f8f52f;
  /* 任意の背景色 */
  ;
  /* 任意の背景色 */
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 0, 100% 100%);
  /* clip-path の形状を変更して好みの形状にすることができます */
  overflow: hidden;
}

.parent {
  text-align: right;
}

.black-card {
  background-color: #333;
  /* こちらの色を変更して、希望の背景色に設定 */
  color: white;
  /* テキストの色 */
}

/* スクロールバー全体 */
::-webkit-scrollbar {
  width: 5px;
}

/* トラック（背景） */
::-webkit-scrollbar-track {
  background: #000;  /* ダーク背景 */
}

/* ハンドル（バー） */
::-webkit-scrollbar-thumb {
  background: linear-gradient(100deg, #00ff62, #f8f52f); /* 緑と黄色のグラデーション */
  border-radius: 10px; /* 角を丸く */
  box-shadow: 0 0 10px #fff,  /* 内側の白い光 */
              0 0 5px #00ff62,  /* 緑の光 */
              0 0 15px #00ff62,
              0 0 20px #00ff62,
              0 0 25px #00ff62,
              0 0 30px #00ff62,
              0 0 35px #00ff62;
}

/* ハンドル（バー）: Hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #00ff62, #f8f52f); /* グラデーション変更 */
}
</style>