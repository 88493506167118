<template>
  <v-main>
    <v-row
      justify="center"
      class="mt-8"
      v-if="isMobile"
    >
      <v-col cols="11" sm="6">
        <v-text-field
          class="neon-glow"
          v-model="searchQuery"
          label="検索"
          solo
          append-icon="mdi-magnify"
          hide-details
          dense
          clearable
          dark
          @keyup.enter="onSearchInput(searchQuery)"
          @click:append="onSearchInput(searchQuery)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-8 mb-5"
    >
      <v-col cols="11" md="8" xl="7">
        <div>

          <!-- ユーザー検索結果 -->
          <div v-if="this.result === 1 && users.length">
            <div class="title-text">
              Users
            </div>
            <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

            <v-card dark class="mt-5 mb-5">
              <v-list v-for="user in users" :key="user.id" class="pb-0 pt-0">
                <v-list-item :to="`/user/${user.id}`">
                  <v-list-item-avatar tile size="50">
                    <v-img :src="user.face"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <router-link :to="`/user/${user.id}`" class="no-underline name"></router-link>
                    <v-list-item-title class="name">
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-title class="">
                      {{ user.world }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
          <!-- ユーザー検索結果 -->

          <!-- チーム検索結果 -->
          <div v-if="this.result === 1 && teams.length">
            <div class="title-text">
              Teams
            </div>
            <v-divider class="neon-divider" style="border-width: 1.5px;"></v-divider>

            <v-card dark class="mt-5 mb-5">
              <v-list v-for="team in teams" :key="team.id" class="pb-0 pt-0">
                <v-list-item :to="`/team/${team.id}`">
                  <v-list-item-content>
                    <v-list-item-title class="name">
                      {{ team.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </v-card>
          </div>
          <!-- チーム検索結果 -->

          <!-- 検索結果無し -->
          <div
            v-if="this.result === 2 && !users.length && !teams.length"
            class="text mt-10"
            align="center"
          >
            キーワードに一致するユーザー及びチームは存在しませんでした。
          </div>
          <!-- 検索結果無し -->

        </div>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import axios from "axios";

export default {
  data(){
    return {
      users: [],
      teams: [],
      searchQuery: '',
      isMobile: false,
      result: '',
    }
  },

  watch: {
    '$route.params.keyword': 'fetchSearchResults'
  },
  methods: {
    async fetchSearchResults() {
      try {
        const keyword = this.$route.params.keyword;
        const userResponse = await axios.get(`/search_users/?search_word=${keyword}`);
        const teamResponse = await axios.get(`/search_teams/?search_word=${keyword}`);

        this.users = userResponse.data;
        this.teams = teamResponse.data;

        if (this.users.length > 0 || this.teams.length > 0) {
          this.result = 1
        } else {
          this.result = 2
        }
      } catch (error) {
        console.error("Error during search:", error);
      }
    },

    async onSearchInput(searchQuery) {
      if (!searchQuery) return;
      this.$router.push({
        name: 'Search',
        params: {
          keyword: searchQuery
        }
      });
    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 971;
    },
  },

  created() {
    this.fetchSearchResults();
  },

  mounted() {
    document.title = "C.C.Community - 検索";
    // ウィンドウの幅が959px以下の場合、スマホ表示と判断(何故か12pxズレがある)
    this.isMobile = window.innerWidth <= 971;
    window.addEventListener('resize', this.checkMobile);
  },
}

</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
.name {
  color: #f8f52f;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 100%;
}
.text {
  font-family: 'Orbitron', sans-serif !important;
  font-size: 100%;
  color: #f8f52f;
  letter-spacing: 2px;
  animation: warning-glow 1.5s infinite alternate;
  font-weight: 300;
  text-shadow: 4px 5px 4px rgba(0, 0, 0, 0.5);
}
</style>



