<template>
  <v-row>
    <v-col>
      <v-btn
        outlined
        class="neon-btn mt-3"
        @click="openDialog"
        v-if="this.admission === false"
      >
        チームに招待する
      </v-btn>
      <v-dialog
        v-model="dialog"
        :width="cardWidth_1"
      >
        <v-card
          elevation="5"
          dark
        >
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col>
              <div
                class="title-text pt-3 pb-2 pl-3"
                align="center"
              >
                Invite to Team
              </div>
              <v-divider
                color="#5A49B5"
                no-gutters
                class="mb-5 ml-3 mr-3"
                style="border-width: 1.5px;"
              ></v-divider>
            </v-col>
          </v-row>
          <v-row style="height: 100%;" justify="center" no-gutters class="mt-4 mb-5">
            <v-col cols="10">
              <div class="text-body-1" align="center">
                {{ name }}さんをチームに招待しますか？
              </div>
            </v-col>
          </v-row>
          <!-- キャンセル・招待ボタン -->
          <v-row no-gutters justify="center" class="pt-4 pb-4">
            <v-col cols="8">
              <v-row no-gutters justify="center">
                <v-col cols="5" sm="4" md="3">
                  <v-btn
                    class="neon-btn"
                    tile
                    outlined
                    width="100%"
                    @click="recruitMember"
                  >
                    招待
                  </v-btn>
                  <!-- 招待が成功したときに表示されるダイアログ -->
                  <v-dialog
                    v-model="dialog_1"
                    :width="cardWidth"
                    persistent
                  >
                    <v-card
                      dark
                      elevation="5"
                      class="pa-4 text-center mx-auto"
                    >
                      <v-icon
                        class="mb-5"
                        color="success"
                        icon="mdi-check-circle"
                        size="112"
                      >mdi-check-circle</v-icon>
                      <h2 class="title-text mb-6" style="color: #f8f52f;">Success!</h2>
                      <p class="mb-4 text-medium-emphasis text-body-2">
                        {{ name }}さんが招待を受けると正式にチームに加入します。
                        <br>
                        ※正式加入まではチームページには表示されません。
                      </p>
                      <v-divider class="mb-4"></v-divider>
                      <div class="text-end">
                        <v-btn
                          class="neon-btn"
                          width="90"
                          @click="successRecruiting"
                        >
                          閉じる
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                  <!-- 招待が成功したときに表示されるダイアログ -->

                  <!-- 招待が送れなかった場合に表示されるダイアログ -->
                  <v-dialog
                    v-model="dialog_error"
                    :width="cardWidth"
                    persistent
                  >
                    <v-card
                      dark
                      elevation="5"
                      class="pa-4 text-center mx-auto"
                    >
                      <v-icon
                        class="mb-5"
                        color="error"
                        icon="mdi-check-circle"
                        size="112"
                      >mdi-close-circle</v-icon>
                      <h2 class="error-text mb-6" style="color: #f8f52f;">Error</h2>
                      <p class="mb-4 text-medium-emphasis text-body-2">
                        チームへの招待に失敗しました。
                        <br>
                        ※招待中のメンバーを含めチームメンバーが7人に達していると新たな招待はできません。
                      </p>
                      <v-divider class="mb-4"></v-divider>
                      <div class="text-end">
                        <v-btn
                          class="neon-cancel-btn"
                          width="90"
                          @click="closeErrorDialog"
                        >
                          閉じる
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                  <!-- 招待が送れなかった場合に表示されるダイアログ -->
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5" sm="4" md="3">
                  <v-btn
                    class="neon-cancel-btn"
                    tile
                    outlined
                    width="100%"
                    @click="dialog=false"
                  >
                    キャンセル
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- キャンセル・招待ボタン -->
        </v-card>
      </v-dialog>
      <v-btn
        outlined
        class="neon-btn mt-3"
        @click="openDialog_2"
        v-if="this.admission === true"
      >
        招待中
      </v-btn>
      <v-dialog
        v-model="dialog_2"
        :width="cardWidth_1"
      >
        <v-card
          elevation="5"
          dark
        >
          <v-row style="height: 100%;" justify="center" no-gutters>
            <v-col>
              <div
                class="title-text pt-3 pb-2 pl-3"
                align="center"
              >
                Pending Invitation
              </div>
              <v-divider
                color="#5A49B5"
                no-gutters
                class="mb-5 ml-3 mr-3"
                style="border-width: 1.5px;"
              ></v-divider>
            </v-col>
          </v-row>
          <v-row style="height: 100%;" justify="center" no-gutters class="mt-5 mb-5">
            <v-col cols="10">
              <div class="text-body-1" align="center">
                {{ name }}さんはチームに招待中です。
                <br>
                招待を取り消しますか？
              </div>
            </v-col>
          </v-row>
          <!-- キャンセルor取り消しボタン -->
          <v-row no-gutters justify="center" class="pt-4 pb-4">
            <v-col cols="8">
              <v-row no-gutters justify="center">
                <v-col cols="5" sm="4" md="3">
                  <v-btn
                    class="neon-btn"
                    tile
                    outlined
                    width="100%"
                    @click="deleteRecruiting"
                  >
                    取り消す
                  </v-btn>
                  <!-- 招待取り消しが成功したときに表示されるダイアログ -->
                  <v-dialog
                    v-model="dialog_3"
                    :width="cardWidth"
                    persistent
                  >
                    <v-card
                      dark
                      elevation="5"
                      class="pa-4 text-center mx-auto"
                    >
                      <v-icon
                        class="mb-5"
                        color="success"
                        icon="mdi-check-circle"
                        size="112"
                      >mdi-check-circle</v-icon>
                      <h2 class="title-text mb-6">Success!</h2>
                      <p class="mb-4 text-medium-emphasis text-body-2">
                        {{ name }}さんへの招待を取り消しました。
                      </p>
                      <v-divider class="mb-4"></v-divider>
                      <div class="text-end">
                        <v-btn
                          class="neon-btn"
                          width="90"
                          @click="successDeleteRecruiting"
                        >
                          閉じる
                        </v-btn>
                      </div>
                    </v-card>
                  </v-dialog>
                  <!-- 招待取り消しが成功したときに表示されるダイアログ -->
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5" sm="4" md="3">
                  <v-btn
                    class="neon-cancel-btn"
                    tile
                    outlined
                    width="100%"
                    @click="dialog_2=false"
                  >
                    キャンセル
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- キャンセルor取り消しボタン -->
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>

export default {
  props: {
    users: [],
    admission: [],
  },
  data(){
    return{
      dialog: false,
      dialog_1: false,
      dialog_2: false,
      dialog_3: false,
      dialog_error: false,
      name: null,
    }
  },
  computed: {
    cardWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '55%'
        case 'md': return '45%'
        case 'lg': return '35%'
        case 'xl': return '28%'
      }
      return 0
    },
    cardWidth_1 () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%'
        case 'sm': return '70%'
        case 'md': return '50%'
        case 'lg': return '45%'
        case 'xl': return '35%'
      }
      return 0
    },
  },
  methods: {

    // dialog(招待用ダイアログ)を開く
    openDialog(){
      this.dialog = true
      this.name = this.users.name
    },

    // dialog_2(招待取り消し用ダイアログ)を開く
    openDialog_2(){
      this.dialog_2 = true
      this.name = this.users.name
    },

    closeErrorDialog(){
      this.dialog_error = false
      this.dialog = false
    },

    // 招待を送るメソッド
    recruitMember(){
      const team_id = this.$store.state.team.id;
      const user_id = this.users.id;
      const url_member_list = "/member_list/" + team_id;
      const url_recruit_member = "/recruit_member/" + user_id;
      const member_status = "member";
      const admission_status = "tentative";

      this.$sendRequest('get', url_member_list)
        .then((response) => {
          if (response.data.length < 7) {
            return this.$sendRequest('post', url_recruit_member, {
              member_status: member_status,
              admission_status: admission_status
            });
          } else {
            this.dialog_error = true;
            throw new Error('Team is full');
          }
        })
        .then(() => {
          this.dialog = false;
          this.dialog_1 = true;
        })
        .catch((e) => {
          this.handleError(e);
        });
    },

    // 招待が送信出来たらdialog_1を開くので、閉じるを押してこのメソッドを呼び出すとページがリロードされる
    successRecruiting(){
      this.dialog_1 = false
      location.reload()
    },

    // 招待している状態の時に使用可能。招待を取り消すメソッド
    deleteRecruiting() {
      const user_id = this.users.id;
      const url_recruit_member = "/recruit_member/" + user_id;

      this.$sendRequest('delete', url_recruit_member)
        .then(() => {
          this.dialog_2 = false;
          this.dialog_3 = true;
        })
        .catch((e) => {
          this.handleError(e);
        });
    },

    // 招待取り消しが送信出来たらdialog_3を開くので、閉じるを押してこのメソッドを呼び出すとページがリロードされる
    successDeleteRecruiting(){
      this.dialog_3 = false
      location.reload()
    },
  }
}
</script>